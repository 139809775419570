import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Tabs, Tab, colors } from "@mui/material";
import { Upload, Button, Select, Flex, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FaFileUpload } from "react-icons/fa";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { getlist } from "../../controllers/Products/product";
import { request } from "../../controllers/Products/inventory";
import { commonList } from "../../controllers/common";

const InventoryAddProductPopUp = ({
  inventoryAddProProductPopUp,
  inventoryToggleAddProductPopUp,
}) => {
  const [tabValue, setTabValue] = useState(0);

  const handleCancel = () => {
    inventoryToggleAddProductPopUp();
    setAllvariants([]);
  };

  const handleDelete = () => {
    notification.open({
      message: "Notification Title",
      description: " Request Send Successfully .",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  /**************************** Integration Start Here *****************/

  const [ALLPRODUCT, setAllProduct] = useState([]);
  const [ALLSHOP, setAllShop] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState();
  const getProductList = async () => {
    try {
      const options = {
        condition: { status: "A" },
        select: { name: true },
      };
      const res = await getlist(options);
      if (res?.status === true && res?.result?.length > 0) {
        setAllProduct(res?.result);
      } else {
        setAllProduct([]);
      }
    } catch (error) {
      setAllProduct([]);
    }
  };

  const getComonList = async () => {
    try {
      const options = {
        list: ["store", "branch"],
        store: { select: { shop_name: true } },
        branch: { select: { storeData: true, branch_name: true } },
      };
      const res = await commonList(options);
      if (res?.status === true && res?.result) {
        setAllShop(res?.result?.store);
        setAllBranch(res?.result?.branch);
      } else {
        setAllShop([]);
        setAllBranch([]);
      }
    } catch (error) {
      setAllBranch([]);
      setAllShop([]);
    }
  };

  useEffect(() => {
    getProductList();
    getComonList();
  }, []);

  const [ALLVARIANTS, setAllvariants] = useState([]);
  /********** This function is used for add more variants ********* */
  const handleAddMorevariants = () => {
    setAllvariants([
      ...ALLVARIANTS,
      {
        unit: "",
        price: "",
        qty: "",
        discount: "",
      },
    ]);
  };
  /******* This function is used for dynamically render All Varients ********** */
  const variants = (ALLVARIANTS = []) => {
    return ALLVARIANTS.map((items, index) => (
      <div key={index} className="variantCount row">
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="form-group vendor_Add_edit_data_form">
            <label htmlFor="unit" className="vendor_popup_product_name">
              Unit
            </label>
            <input
              title="Enter your last name."
              type="text"
              name={`unit_${index}`}
              className="form-control store_input_field_field"
              id="unit"
            />
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="form-group vendor_Add_edit_data_form">
            <label htmlFor="Price" className="vendor_popup_product_name">
              Price{" "}
              <span style={{ fontSize: "xx-small" }}>(Inclusive of VAT)</span>
            </label>
            <input
              title="Enter your last name."
              type="number"
              name={`price_${index}`}
              className="form-control store_input_field_field"
              id="Price"
            />
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="form-group vendor_Add_edit_data_form">
            <label htmlFor="qty" className="vendor_popup_product_name">
              Qty
            </label>
            <input
              title="Enter your last name."
              type="number"
              name={`qty_${index}`}
              className="form-control store_input_field_field"
              id="qty"
            />
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="form-group vendor_Add_edit_data_form">
            <label htmlFor="discount" className="vendor_popup_product_name">
              Discount (%)
            </label>
            <input
              title="Enter your last name."
              type="number"
              name={`discount_${index}`}
              className="form-control store_input_field_field"
              id="discount"
            />
          </div>
        </div>
      </div>
    ));
  };

  /*********** This function is used for handle form submit ******** */
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      const formData = new FormData(event.target);
      formData.append(
        "branch",
        `${filterBranch[0]?.branch_name}___${filterBranch[0]?._id}`
      );
      const formDataObject = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
      const options = await transformData(formDataObject);
      const isValidate = validateForm(options);
      if (isValidate) {
        const res = await request(options);
        if (res.status === true) {
          event.target.reset();
          setIsLoading(false);
          notification.open({
            message: "Notification Title",
            description: " Request Send Successfully .",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
        } else {
          notification.open({
            message: "Oops!!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          setIsLoading(false);
        }
      } else {
        notification.open({
          message: "Validation Error!",
          description: " Please fill the required fields.",
          placement: "topRight",
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /**** This function is used for generate post form data *********** */
  const transformData = (input) => {
    const { product, store, branch, ...variants } = input;
    const variantKeys = Object.keys(variants).filter(
      (key) => key !== "product"
    );
    const pp = product.split("___");
    const ss = store.split("___");
    const bb = branch.split("___");
    const transformedData = {
      product_name: pp[0],
      productId: pp[1],
      store_name: ss[0],
      storeId: ss[1],
      branch_name: bb[0],
      branchId: bb[1],
      variants: [],
    };

    for (let i = 0; i < variantKeys.length / 4; i++) {
      const unit = variants[`unit_${i}`];
      const price = variants[`price_${i}`];
      const qty = variants[`qty_${i}`];

      // Only add the variant if all fields are non-empty
      if (unit !== "" && price !== "" && qty !== "") {
        transformedData.variants.push({
          unit,
          price,
          qty,
          discount: variants[`discount_${i}`],
        });
      }
    }

    return transformedData;
  };

  /********  */
  const validateForm = (formData) => {
    try {
      const {
        storeId,
        store_name,
        branchId,
        branch_name,
        productId,
        product_name,
        variants,
      } = formData;
      if (!storeId || !store_name) {
        setErrors((pre) => ({
          ...pre,
          product: "Please select the product.",
        }));
        return false;
      } else if (!productId || !product_name) {
        setErrors((pre) => ({
          ...pre,
          store: "Please select the store.",
        }));
        return false;
      } else if (!branchId || !branch_name) {
        setErrors((pre) => ({
          ...pre,
          branch: "Please select the store.",
        }));
        return false;
      } else if (!variants || variants.length === 0) {
        setErrors((pre) => ({
          ...pre,
          variants: "Incomplete variants.",
        }));
        return false;
      } else {
        return true;
      }
    } catch (error) {
      setErrors((pre) => ({
        ...pre,
        formError: "Server is under maintance.",
      }));
      return false;
    }
  };

  const handleChnage = (e) => {
    try {
      const { name, value } = e.target;
      if (name === "store") {
        const val = value.split("___");
        const filteredArray = ALLBRANCH.filter(
          (item) => item.storeData === val[1]
        );
        setFilterBranch(filteredArray);
      }
    } catch (error) {}
  };

  return (
    <Dialog
      open={inventoryAddProProductPopUp}
      onClose={inventoryToggleAddProductPopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Add Inventory
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                {/* <div className="inventory_select_opetion_popup_modal">
                  <span>Select option</span>
                </div> */}
                <form className="" method="post" onSubmit={handleSubmit}>
                  {/* <Tabs
                    value={tabValue}
                    onChange={(event, newValue) => setTabValue(newValue)}
                    aria-label="tabs example"
                    className="frisbee_dashbaord_inventory_popup_tabs"
                    indicatorColor="secondary"
                  > */}
                  {/* <Tab
                      id="tab1"
                      label={
                        <div className="vendor_dashbaord_signle_product">
                          <span>Single product</span>
                          <input
                            type="radio"
                            id="radio1"
                            checked={tabValue === 0}
                            onChange={() => setTabValue(0)}
                            className="vendor_dashboard_radio_color"
                          />
                        </div>
                      }
                      className="frisbee_dashbaord_pop_up_tabs1"
                    /> */}
                  {/* // Second tabs */}
                  {/* <Tab
                      id="tab2"
                      label={
                        <div className="vendor_dashbaord_signle_product">
                          <span>Multiple products</span>
                          <input
                            type="radio"
                            id="radio2"
                            checked={tabValue === 1}
                            onChange={() => setTabValue(1)}
                            className="vendor_dashboard_radio_color"
                          />
                        </div>
                      }
                      className="frisbee_dashbaord_pop_up_tabs1"
                    /> */}
                  {/* </Tabs> */}
                  {/* Content for each tab */}
                  {tabValue === 0 && (
                    <div>
                      <div>
                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                          <label
                            htmlFor="product"
                            className="vendor_popup_product_name all_top_and_bottom_for_input_text"
                          >
                            Product :
                          </label>
                          <select
                            name="product"
                            id="product"
                            className="form-control all_input_tag_tagenter_product_"
                          >
                            <option>Select products</option>
                            {ALLPRODUCT?.length > 0 ? (
                              ALLPRODUCT.map((items, index) => (
                                <>
                                  <option
                                    key={index}
                                    value={`${items?.name}___${items?._id}`}
                                  >
                                    {items?.name}
                                  </option>
                                </>
                              ))
                            ) : (
                              <option>No Data Found</option>
                            )}
                          </select>
                          {errors?.product ? (
                            <p style={{ colors: "red" }}>{errors.product}</p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                          <label
                            htmlFor="store"
                            className="vendor_popup_product_name all_top_and_bottom_for_input_text"
                          >
                            Store :
                          </label>
                          <select
                            name="store"
                            id="store"
                            className="form-control all_input_tag_tagenter_product_"
                            onChange={handleChnage}
                          >
                            <option value="">Select Store</option>
                            {ALLSHOP?.length > 0 ? (
                              ALLSHOP.map((items, index) => (
                                <>
                                  <option
                                    key={index}
                                    value={`${items?.shop_name}___${items?._id}`}
                                  >
                                    {items?.shop_name}
                                  </option>
                                </>
                              ))
                            ) : (
                              <option value="">No Data Found</option>
                            )}
                          </select>
                          {errors?.store ? (
                            <p style={{ colors: "red" }}>{errors.store}</p>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                          <label
                            htmlFor="store"
                            className="vendor_popup_product_name all_top_and_bottom_for_input_text"
                          >
                            Branch :
                          </label>
                          <select
                            name="branch"
                            id="branch"
                            className="form-control all_input_tag_tagenter_product_"
                          >
                            <option value="">Select Branch</option>
                            {filterBranch?.length > 0 ? (
                              filterBranch.map((items, index) => (
                                <>
                                  <option
                                    key={index}
                                    value={`${items?.branch_name}___${items?._id}`}
                                  >
                                    {items?.branch_name}
                                  </option>
                                </>
                              ))
                            ) : (
                              <option value="">No Data Found</option>
                            )}
                          </select>
                          {errors?.branch ? (
                            <p style={{ colors: "red" }}>{errors.branch}</p>
                          ) : (
                            ""
                          )}
                        </div> */}
                        {/* // next line */}
                        {variants(ALLVARIANTS)}
                        <div className="all_top_and_bottom_for_input_text">
                          <Link onClick={handleAddMorevariants}>
                            Add {ALLVARIANTS.length > 0 ? "more" : ""} variant
                          </Link>
                        </div>
                        {errors?.variants ? (
                          <p style={{ color: "red" }}>{errors.variants}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inventory_open_popup_action_btn_upload_main">
                        {errors?.formError ? (
                          <p style={{ colors: "red" }}>{errors.formError}</p>
                        ) : (
                          ""
                        )}
                        <button
                          type="button"
                          className="btn btn-light categories_cancel_button"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-danger "
                          // onClick={handleDelete}
                        >
                          Raise request
                        </button>
                      </div>
                    </div>
                  )}
                  {tabValue === 1 && (
                    <div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data inventory_upload_excel_section">
                        <label htmlFor="company_registration_document">
                          Upload excel
                        </label>
                        <div
                          className="sign_up_file_upload_for_inventory"
                          id="company_registration_document"
                        >
                          <div className="company_registration_document_button">
                            Upload File
                          </div>
                          <Upload id="company_registration_document">
                            <Button icon={<FaFileUpload />}>Upload File</Button>
                          </Upload>
                        </div>
                      </div>
                      <div className="inventory_open_popup_action_btn_upload_main">
                        <button
                          type="button"
                          className="btn btn-light categories_cancel_button"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger "
                          // onClick={handleDelete}
                        >
                          Raise request
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </Typography>
        </div>
      </div>
      <Flex align="center" gap="middle">
        <Spin size="large" spinning={isLoading} fullscreen={true} />
      </Flex>
    </Dialog>
  );
};

export default InventoryAddProductPopUp;
