import {API_VENDOR_URL} from '../../config/constants'
import { postRequest } from '../API'

/*********************************************************
* Function Name : List
* Description   : Get list of all service category
* By            : Noor Alam
* Date          : 8-June-2024 
*********************************************************/
export const shop = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}payments/shop`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count : res?.data?.response?.count};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : grooming
* Description   : Get list of all service bookings payment
* By            : Noor Alam
* Date          : 8-June-2024 
*********************************************************/
export const grooming = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}payments/grooming`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count : res?.data?.response?.count};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : boarding
* Description   : Get list of all service bookings payment
* By            : Noor Alam
* Date          : 8-June-2024 
*********************************************************/
export const boarding = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}payments/boarding`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count : res?.data?.response?.count};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : veterinary
* Description   : Get list of all service bookings payment
* By            : Noor Alam
* Date          : 8-June-2024 
*********************************************************/
export const veterinary = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}payments/veterinary`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count : res?.data?.response?.count};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : training
* Description   : Get list of all service bookings payment
* By            : Noor Alam
* Date          : 8-June-2024 
*********************************************************/
export const training = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}payments/training`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count : res?.data?.response?.count};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : dayCare
* Description   : Get list of all service bookings payment
* By            : Noor Alam
* Date          : 8-June-2024 
*********************************************************/
export const dayCare = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}payments/day-care`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count : res?.data?.response?.count};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function