import React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import Slide from "@mui/material/Slide";
import "./OrderMainContnet.css";

const OrderFilterDrawer = ({ drawerOpen, RequesttoggleDrawer }) => {
  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={RequesttoggleDrawer}
      className="reuest_filter_drawer_main_section"
    >
      <Slide direction="left" in={drawerOpen}>
        <div className="myDrawer drawer_for_width">
          <div className="myMainDrawerClass">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Filters
            </Typography>
            <div>
              <IconButton onClick={RequesttoggleDrawer}>
                <IoMdClose />
              </IconButton>
            </div>
          </div>
          <Typography variant="body1" component="div">
            <form action="" className="reuest_list_form_section">
              <div className="reuest_list_form_section_div">
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                <label for="vehicle1"> All</label>
              </div>
              <br></br>
              <div className="reuest_list_form_section_div">
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                <label for="vehicle1"> categories</label>
              </div>
              <br></br>
              <div className="reuest_list_form_section_div">
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                <label for="vehicle1"> Products</label>
              </div>
              <br></br>
              <div className="reuest_list_form_section_button">
                <button type="button">Apply</button>
              </div>
            </form>
          </Typography>
        </div>
      </Slide>
    </Drawer>
  );
};

export default OrderFilterDrawer;
