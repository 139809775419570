import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, Switch, Drawer, Button, Space  } from "antd";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import Top_navbar from '../DashBaord/Top_navbar'
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { ASSETS_BASE_URL } from "../../config/constants";
import SkeltonList from "../../components/SkeltonEffect/list";
import { list } from "../../controllers/services/requestController";
import { getPage, RequestStatusMessage } from '../../controllers/common';



function Inventory(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

Inventory.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  // Add product PopUp
  const [inventoryAddProProductPopUp, setAddProductPopUpOpen] = useState(false);
  const inventoryToggleAddProductPopUp = () => {
    setAddProductPopUpOpen(!inventoryAddProProductPopUp);
  };
  //for PopUP modal
  const [inventoryPopUp, setPopUpOpenInventory] = useState(false);
  const inventoryTogglePopUp = () => {
    setPopUpOpenInventory(!inventoryPopUp);
  };

  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  };

  //remove or delete popup
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    // console.log("Toggle Popup");
    setPopUpOpen(!popUpOpen);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  /************************************************************************************* */
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [deleteId, setDeleteId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: '',
    to: new Date(),
    filter_by: "",
    search: ""
  })//End

  const [showRequest, setShowRequest] = useState("");

  const handleEdit = async (item = {}) => {
    navigate('/vendors/addeditdata', { state: item });
  }
  /*********************************************************
  *  This function is use to fetch vendor list
  *********************************************************/
  const getList = async () => {
    setListData([]);
    const options = {
      type: "",
      conditon: {
        ...(showRequest ? { status: showRequest } : null)
      },
      ...(filter ? { filter: filter } : null),
      select: {},
      sort: { "_id": -1 },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10
    }
    const listData = await list(options);
    if (listData.status === true) {
      if (listData.result.length > 0) {
        setListData(listData?.result);
        setTotalPages(getPage(listData?.count));
      } else {
        setListData([{ vendor_first_name: 'No Data Found' }]);
        setTotalPages(getPage(0));
      }
      const countOptions = {
        type: "count",
        condition: { ...(showRequest ? { status: showRequest } : null) },
        ...(filter ? { filter: filter } : null)
      };
      const count = await list(countOptions);
      setTotalPages(getPage(count?.result));
    } else {
      setListData([{ vendor_first_name: 'No Data Found' }]);
    }
  };//End

  /*********************************************************
  *  This function is for handle page change
  *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  /*********************************************************
*  This function is ued for handle filter input chnage
*********************************************************/
const handleFilterReset = () => {
  try {
    setFilter('');
    inputRef1.current.value = '';
    inputRef2.current.value = '';
    inputRef3.current.value = '';
    inputRef4.current.value = '';
  } catch (error) {

  }
}

const handleFilterApply = (e) => {
  try {
    e.preventDefault();
    const form = new FormData(e.target);
    setFilter((pre) => ({
      ...pre,
      'filter_by': form.get('filter_by') || '',
      'search': form.get('search') || '',
      'to': form.get('to_date') || '',
      'from': form.get('from_date') || '',

    }))
    handleFiterDrawer();
  } catch (error) {

  }
}
  /*********************************************************
  *  This function is load when page load and with dependency update
  *********************************************************/
  useEffect(() => {
    getList();
    document.title = 'Frisbee Vendor Admin || Request List'
  }, [currentPage, showRequest, popUpOpen, filter]);

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1" onClick={inventoryTogglePopUp}>
        {/* <Link to="/inventoryEditPage"> */}
        <FaEdit />
        <span className="show_span_edit">Edit</span>
        {/* </Link> */}
      </Menu.Item>
      <Menu.Item key="2" onClick={togglePopUp}>
        <MdDelete /> <span className="show_span">Remove</span>
      </Menu.Item>
    </Menu>
  );

  const getVariantsString = (variants) => {
    if (!Array.isArray(variants)) return '';

    let variantsString = '';

    variants.forEach((item, index) => {
      variantsString += `<p key=${index}>`
      variantsString += `<span>Unit : ${item.unit} | </span>`
      variantsString += `<span>Price : AED ${item.price} | </span>`
      variantsString += `<span>QTY : ${item.qty} | </span>`
      variantsString += `<span>Disc. : ${item?.discount ? item.discount : 0} %</span>`
      variantsString += `</p>`;
    });

    return variantsString;
  };

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <Top_navbar title={`Request List`}></Top_navbar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                    />
                  </Tabs>
                </div>
                <div className="fa_filter_rating_review"  onClick={handleFiterDrawer}>
                  <div className="inventory_filter_div">
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt=""
                      className="user_iconses"
                    />

                    <Link href="#" className="filter_btn_invontry_rating_review">
                      Filters
                    </Link>
                  </div>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <Inventory value={value} index={0}>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Service</th>
                              {/* <th>Store</th> */}
                              <th>Branch</th>
                              <th>Category</th>
                              <th>Sub Category</th>
                              <th>Price</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (ALLLISTDATA.map((items, index) => (<>
                              <tr key={index}>
                                <td width="100px">
                                  <div className="admin_prodcut_list_span">
                                    <img
                                      src={`${ASSETS_BASE_URL}${items?.image}`}
                                      alt=""
                                    />
                                    <span className="admin_prodcut_list_span_name">
                                      {items?.name}
                                    </span>
                                  </div>
                                </td>
                                {/* <td>
                                  <span>{items?.store?.shop_name}</span><br/>
                                  <span>{items?.store?.phone}</span><br/>
                                  <span>{items?.store?.email}</span><br/>
                                  <span>{items?.store?.city}</span>
                                  </td> */}
                                <td>
                                  <span>{items?.branch?.branch_name}</span><br />
                                  <span>{items?.branch?.phone}</span><br />
                                  <span>{items?.branch?.email}</span><br />
                                  <span>{items?.branch?.city}</span>
                                </td>
                                <td>{items?.category?.name}</td>
                                <td>{items?.subcategory?.name}</td>
                                <td> AED {items?.price}</td>
                                <td dangerouslySetInnerHTML={{ __html: RequestStatusMessage(items?.status) }}></td>
                              </tr>
                            </>))) : (<SkeltonList row={5} col={5} />)}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={TOTALPAGES} onChange={handlePageChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Inventory>
          </Box>
        </div>
      </div>
       {/* Filter */}
       <Drawer
        title="Inventory Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}>
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id='filter_form' onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  Search Field :
                </label>
                <select name="filter_by" className="form-control store_input_field_field" id="filter_by" ref={inputRef1}>
                  <option value=''>Select search field</option>
                  <option value='store_name' selected={filter?.filter_by === 'store_name' ? true : false}>Store Name</option>
                  <option value='store_phone' selected={filter?.filter_by === 'store_phone' ? true : false}>Store Phone</option>
                  <option value='store_email' selected={filter?.filter_by === 'store_email' ? true : false}>Store Email</option>
                  <option value='category' selected={filter?.filter_by === 'category' ? true : false}>Category</option>
                  <option value='subcategory' selected={filter?.filter_by === 'subcategory' ? true : false}>Sub-Category</option>
                  <option value='price' selected={filter?.filter_by === 'price' ? true : false} >Price</option>
                  <option value='status' selected={filter?.filter_by === 'status' ? true : false}>Status</option>
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field_field"
                  id="search"
                  ref={inputRef2}
                // onChange={handleFilterChnage} 
                // value={filter?.search}             
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  From :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="from_date"
                  className="form-control store_input_field_field"
                  id="from_date"
                  ref={inputRef3}
                // onChange={handleFilterChnage}          
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  To :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="to_date"
                  className="form-control store_input_field_field"
                  id="to_date"
                  ref={inputRef4}
                // onChange={handleFilterChnage}             
                />
              </div>
              <br />
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-primary "
                  style={{ backgroundColor: "black", color: "white",border:"none" }}
                >Apply</button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
}
