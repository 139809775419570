import { API_VENDOR_URL } from "../../config/constants";
import { postRequest,fetchIpAddress } from "../API";

/*********************************************************
* Function Name : List
* Description   : Get list of all service category
* By            : Md RAshid Ali
* Date          : 26-02-2024 
*********************************************************/
export const list = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}productsmaster/inventory-list`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : shopList
* Description   : Get list of all shop
* By            : Afsar Ali
* Date          : 26-03-2024 
*********************************************************/
export const shopList = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}users/shop/list`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : request
* Description   : create ne request 
* By            : Afsar Ali 
* Date          : 26-03-2024 
*********************************************************/
export const request = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}productsmaster/request`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : requestList
* Description   : Get request list of product
* By            : Afsar Ali
* Date          : 26-03-2024 
*********************************************************/
export const requestList = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}productsmaster/request/list`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count : res?.data?.response?.count};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : updateVarient
* Description   : update varients 
* By            : Afsar Ali 
* Date          : 07-06-2024 
*********************************************************/
export const updateVarient = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}productsmaster/update-inventory`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function
