import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone, CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";


import { requestCategory } from "../../controllers/categories";

const CategoriesOpenPopUp = ({ categoriesPopUp, togglePopUp, type="Product", title="Request Category" }) => {
  const handleCancel = () => {
    setFormData("");
    setErrors("");
    togglePopUp();
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  const [errors, setErrors] = useState("");
  const [formData, setFormData] = useState({
    request : type,
    name : "",
    description : ""
  });

  const handleChange = (e) => {
    setFormData((err) => ({
      ...err,
      [e.target.name] : e.target.value
    }))
    setErrors((preErr)=>({
      ...preErr,
      [e.target.name] : ""
    }))
  }

  const handleSubmit = async () => {
    try{
      const { request, name, description } = formData;
      if(!request){
        setErrors((preErr)=>({
          ...preErr,
          request : "Your request is not sent! Please Try again."
        }))
      } else if(!name){
        setErrors((preErr)=>({
          ...preErr,
          name : "Name is required."
        }))
      } else if(!description){
        setErrors((preErr)=>({
          ...preErr,
          description : "Description is required"
        }))
      } else{
        const options = {
          ...formData,
          request : type 
        }
        const res = await requestCategory(options);
        if(res.status === true){
          notification.open({
            message: "Request Sent.",
            description: " Request Sent Successfully.",
            placement: "topRight",
            duration : 2,
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          handleCancel();
        } else{
          notification.open({
            message: "Oops!",
            description: " Request Not Sent Successfully.",
            placement: "topRight",
            duration : 2,
            icon: <CloseCircleOutlined twoToneColor="#52c41a" />,
          });
          handleCancel();
        }
      }
    } catch (error){
      notification.open({
        message: "Oops!",
        description: " Server is under maintanance. Please try after some time",
        placement: "topRight",
        duration : 2,
        icon: <InfoCircleOutlined twoToneColor="#52c41a" />,
      });
      handleCancel();
    } 
  }
  return (
    <Dialog
      open={categoriesPopUp}
      onClose={togglePopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="categories_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="categories_popup_title_heading"
            >
              {title}
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form">
                  <div class="form-group">
                    <label for="name" className="all_lable_for_vendor_dashbaord" >
                      Category Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        onChange={handleChange}
                        value={formData?.name}
                        class="form-control store_input_field_field"
                        placeholder="Enter Category Name"
                      />
                      {errors?.name ? (<p style={{ color: "red" }}>{errors?.name}</p>) : ("")}
                  </div>
                  <div class="form-group">
                    <div class="form-group">
                      <label
                        for="description"
                        className="all_lable_for_vendor_dashbaord"
                      >
                        Category Description
                      </label>
                      <input
                        type="text"
                        name="description"
                        onChange={handleChange}
                        value={formData?.description}
                        class="form-control store_input_field_field"
                        id="description"
                        placeholder="Enter Description"
                      />
                      {errors?.description ? (<p style={{ color: "red" }}>{errors?.description}</p>) : ("")}
                    </div>
                  </div>
                  <div className="all_button_cancel_and_submit">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={handleSubmit}
                    >
                      Send Request
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};

export default CategoriesOpenPopUp;
