import React from "react";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { FaDesktop } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";

function RatingAndReviewEditPage() {
  const { Dragger } = Upload;
  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <div>
            <h5 className="inventory_edit_page_heading">Rating And Review Edit Page</h5>
          </div>
          <Link to="/aboutUsShowingpage">
            <div>
              <button type="button" className="btn btn-secondary">
                <FaDesktop /> Show
              </button>
            </div>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-header py-3 invetory_title_div">
            <div>
              <h6 className="m-0 font-weight-bold text-primary">Title</h6>
              {/* <h6 className="m-0 font-weight-bold text-primary">Story</h6> */}
            </div>
            <div>
              <button type="button" className="btn btn-danger">
                Delete Data
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="responsive">
              <form action="" className="inventory_form_main_contnet">
                <div className="form-group">
                  <label htmlFor="exampleInputText">Title</label>
                  <input
                    type="text"
                    className="form-control store_input_field_field"
                    id="exampleInputText"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Description</label>
                  <ReactQuill className="inventory_react_quill" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Images</label>
                  <Dragger className="about_us_dragger_file">
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload. Strictly prohibited
                      from uploading company data or other banned files.
                    </p>
                  </Dragger>
                </div>
                <div className="inventory_edit_submit_btn">
                  <button
                    type="button"
                    className="btn btn-primary inventory_save_button"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RatingAndReviewEditPage;
