import React, { useEffect, useState } from "react";
import "./main_dashboard.css";
import Top_navbar from "./Top_navbar";
import Recent_order from "./Recent_order";
import { Select, Skeleton } from "antd";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { getDashboardData } from '../../controllers/accounts/Account'
import TransactionList from "./TransactionList";


const Dashboard = () => {
  // const [SALES, setSales] = useState('');
  const [salesText, setSalesText] = useState('Total');
  const [REVENUE, setRevenue] = useState('');
  const [ORDER, setOrder] = useState('');
  const [PRODUCTS, setProducts] = useState('');
  const [revenueText, setRevenueText] = useState('Total');
  const [TRANSACTION, setTransaction] = useState('');
  const [transactionText, setTransactionText] = useState('Shop');

  //new
  const [ orderCount, setOrderCunt ] = useState([]);
  const [ bookingCount, setBookingCount ] = useState([]);
  const [ SALES, setSales ] = useState([]);
  const [ GRAPH, setGraph ] = useState([]);
  
  
  const [ showOrderCount, setShowOrderCunt ] = useState('Total');
  const [ showBookingCount, setShowBookingCount ] = useState('total');
  const [ showSales, setShowSales ] = useState('total');
  


  const getData = async () => {
    try {
      const res = await getDashboardData();
      if (res.status === true) {
        console.log("res getDashboardData", res)
        setOrderCunt(res?.result?.orderCount || []);
        // setShowOrderCunt(res?.result?.orderCount?.total || 0);

        setBookingCount(res?.result?.bookingCount || []);
        // setShowBookingCount(res?.result?.bookingCount?.total || 0 );

        setSales(res?.result?.sales || []);
        // setShowSales(res?.result?.sales?.total || []);

        setGraph(res?.result?.graph || []);
        // setShowGraph(res?.result?.graph?.shop || []);

      } else {
        
      }
    } catch (error) {
      
    }
  }

  const handleOrderCount = (value, option) => {
    try {
      setShowOrderCunt(value)
    } catch (error) {
      console.log('error', error)
    }
  };

  const handleBookingCount = (value, option) => {
    try {
      setShowBookingCount(value)
    } catch (error) {
      console.log('error', error)
    }
  };

  const handleSalesChange = (value, option) => {
    try {
      setShowSales(value)
    } catch (error) {
      console.log('error', error)
    }
  };

  

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, [])



  return (
    <>
      <Top_navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 px-0">
            <div className="transaction_form">

              <div className="transaction_Card margin-right">
                <div className="transction_main_Card ">
                  <h1>Orders</h1>
                  <div>
                    <Select
                      defaultValue={showOrderCount}
                      className="select_filter_class"
                      onChange={handleOrderCount}
                      name="sales"
                      style={{
                        width: 120,
                      }}
                      bordered={false}
                      options={[
                        {
                          value: "Total",
                          label: "Total",
                        },
                        {
                          value: "Pending",
                          label: "Pending",
                        },{
                          value: "Processing",
                          label: "Processing",
                        },
                        {
                          value: "Shipped",
                          label: "Shipped",
                        },
                        {
                          value: "Complete",
                          label: "Complete",
                        }
                      ]}
                    />
                  </div>
                </div>
                <p className="presentation">
                  This represents total products in <br></br> past 6 months.
                </p>
                <>
                  {orderCount.length === 0 ? (
                    <Skeleton loading={true} active paragraph={{ rows: 0 }}>
                    </Skeleton>
                  ) : (<>
                    <p className="sales_text">{` ${orderCount[showOrderCount] || 0}`}</p>
                  </>)}
                </>

              </div>

              <div className="transaction_Card margin-right">
                <div className="transction_main_Card ">
                  <h1>Bookings</h1>
                  <div>
                    <Select
                      defaultValue={showBookingCount}
                      className="select_filter_class"
                      onChange={handleBookingCount}
                      name="sales"
                      style={{
                        width: 120,
                      }}
                      bordered={false}
                      options={[
                        {
                          value: "total",
                          label: "Total",
                        },
                        {
                          value: "grooming",
                          label: "Grooming",
                        },
                        {
                          value: "boarding",
                          label: "Boarding",
                        },
                        {
                          value: "veterinary",
                          label: "Veterinary",
                        },
                        {
                          value: "training",
                          label: "Training",
                        },
                        {
                          value: "dayCare",
                          label: "Day Care",
                        }
                      ]}
                    />
                  </div>
                </div>
                <p className="presentation">
                  This represents total products in <br></br> past 6 months.
                </p>
                <>
                  {bookingCount.length === 0 ? (
                    <Skeleton loading={true} active paragraph={{ rows: 0 }}>
                    </Skeleton>
                  ) : (<>
                    <p className="sales_text">{` ${bookingCount[showBookingCount]}`}</p>
                  </>)}
                </>

              </div>
              
              <div className="transaction_Card">
                <div className="transction_main_Card">
                  <h1>Sales</h1>
                  <div>
                    <Select
                      defaultValue={showSales}
                      className="select_filter_class"
                      onChange={handleSalesChange}
                      name="sales"
                      style={{
                        width: 120,
                      }}
                      bordered={false}
                      options={[
                        {
                          value: "total",
                          label: "Total",
                        },
                        {
                          value: "shop",
                          label: "Shop",
                        },
                        {
                          value: "grooming",
                          label: "Grooming",
                        },
                        {
                          value: "boarding",
                          label: "Boarding",
                        },
                        {
                          value: "veterinary",
                          label: "Veterinary",
                        },
                        {
                          value: "training",
                          label: "Training",
                        },
                        {
                          value: "daycare",
                          label: "Day Care",
                        }
                      ]}
                    />
                  </div>
                </div>
                <p className="presentation">
                  This represents total orders and services in <br></br> past 6 months.
                </p>
                <>
                  {SALES.length === 0 ? (
                    <Skeleton loading={true} active paragraph={{ rows: 0 }}>
                    </Skeleton>
                  ) : (<>
                    <p className="sales_text">{`AED ${SALES[showSales].toFixed(2)}`}</p>
                  </>)}
                </>
              </div>

            </div>

           
          </div>

          {/* <div className="col-md-12">
            <div className="transaction_Card">
              <div className="transction_main_Card">
                <h1>Transactions</h1>
                <div>
                  <Select
                    defaultValue={transactionText}
                    onChange={handleTransactionChange}
                    style={{
                      width: 100,
                    }}
                    bordered={false}
                    options={[
                      {
                        value: "Shop",
                        label: "Shop",
                      }, {
                        value: "Grooming",
                        label: "Grooming",
                      },
                      {
                        value: "Boarding",
                        label: "Boarding",
                      },
                      {
                        value: "Veterinary",
                        label: "Veterinary",
                      },
                      {
                        value: "Training",
                        label: "Training",
                      },
                      {
                        value: "Day Care",
                        label: "Day Care",
                      }
                    ]}
                  />
                </div>
              </div>
              <ul>
                {transactionText === 'Shop' && (<TransactionList transactions={TRANSACTION?.shop} />)}
                {transactionText === 'Grooming' && (<TransactionList transactions={TRANSACTION?.grooming} />)}
                {transactionText === 'Boarding' && (<TransactionList transactions={TRANSACTION?.boarding} />)}
                {transactionText === 'Training' && (<TransactionList transactions={TRANSACTION?.training} />)}
                {transactionText === 'Veterinary' && (<TransactionList transactions={TRANSACTION?.veterinary} />)}
                {transactionText === 'Day Care' && (<TransactionList transactions={TRANSACTION?.dayCare} />)}
              </ul>
            </div>
          </div> */}
        </div>

        {/* tables */}
        <div className="row">
          <div className="col-md-12 inventory_tabs_main_div_card">
            <Recent_order
              graphData = {GRAPH}
              // handleGraphChange = {handleGraphChange}
              // show = {showGraph} 
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
