// import create from "zustand";

// export const useUserStore = create((set) => ({
//   currentUser: null,
//   isLoading: true,
//   fetchUserInfo: () => {
//     const adminInfo = sessionStorage.getItem("ADMIN-INFO");
//     if (adminInfo) {
//       const userInfo = JSON.parse(adminInfo);
//       set({ currentUser: userInfo, isLoading: false });
//     } else {
//       set({ currentUser: null, isLoading: false });
//     }
//   },
// }));
// import create from "zustand";
// import { doc, getDoc } from "firebase/firestore";
// import { db } from "../config/firebaseConfig";

// export const useUserStore = create((set) => ({
//   currentUser: null,
//   isLoading: true,
//   fetchUserInfo: async (uid) => {
//     if (!uid) return set({ currentUser: null, isLoading: false });

//     try {
//       const docRef = doc(db, "users", uid);
//       const docSnap = await getDoc(docRef);

//       if (docSnap.exists()) {
//         const userData = docSnap.data();
//         console.log("Fetched user data:", userData); // Debug log
//         set({ currentUser: { ...userData, id: uid }, isLoading: false });
//       } else {
//         set({ currentUser: null, isLoading: false });
//       }
//     } catch (error) {
//       console.log("Error fetching user info:", error); // Debug log
//       return set({ currentUser: null, isLoading: false });
//     }
//   },
// }));
import { doc, getDoc } from "firebase/firestore";
import create from "zustand";
import { db } from "../config/firebaseConfig";

export const useUserStore = create((set) => ({
  currentUser: null,
  isLoading: true,
  fetchUserInfo: async (uid) => {
    if (!uid) return set({ currentUser: null, isLoading: false });

    try {
      console.log("Fetching user info for UID:", uid);
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("User data found:", docSnap.data());
        set({ currentUser: { ...docSnap.data(), id: uid }, isLoading: false });
      } else {
        console.log("No user document found for UID:", uid);
        set({ currentUser: null, isLoading: false });
      }
    } catch (error) {
      console.log("Error fetching user info:", error);
      set({ currentUser: null, isLoading: false });
    }
  },
}));
