import React, {useState} from "react";
import "./SignUp.css";
import signUp from "../../Image/sign-up-latest.png";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import frisbeeLogo from "../../Image/frisbee-logo.png";
import { Upload, Button, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { signUp as signup_form } from '../../controllers/accounts/Account'

const SignUp = () => {
  const navigate = useNavigate();
  const [companyDetails, setcompanyDetails] = useState("");
  const [productDetails, setproductDetails] = useState("");

  const [errors, setErrors]=useState("");
  
  const handleFile1 = (e1) => {
    setcompanyDetails(e1.fileList[0].originFileObj);
    setErrors((pre)=>({
      ...pre,
      companyRegistrationDocument : ""
    }));
  }

  const handleFile2 = (e2) => {
    setproductDetails(e2.fileList[0].originFileObj);
    setErrors((pre)=>({
      ...pre,
      productDetailsDocument : ""
    }));
  }

  const handleSubmit = async (e)=>{
    e.preventDefault();
    try {
      if(!companyDetails){
        setErrors((pre)=>({
          ...pre,
          companyRegistrationDocument : "Company registration document required."
        }));
      } else if(!productDetails){
        setErrors((pre)=>({
          ...pre,
          productDetailsDocument : "Product list required."
        }));
      } else{
        const formData = new FormData(e.target);
        formData.append('companyRegistrationDocument',companyDetails );
        formData.append('productDetailsDocument',productDetails );
        const isValidate = validateForm(formData);
        if(isValidate){
          const res = await signup_form(formData);
          if(res.status === true){
            notification.open({
              message: "Request Sent.",
              description: " Request Sent Successfully.",
              placement: "topRight",
              duration : 2,
              // icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
            });
            navigate('/');
          } else{
            notification.open({
              message: "Oops!.",
              description: `${res?.message}`,
              placement: "topRight",
              duration : 2,
              // icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
            });
          }
        } else{
          notification.open({
            message: "Validation Fail.",
            description: `Please filled required fields.`,
            placement: "topRight",
            duration : 2,
            // icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
        }
      }
    } catch (error) {
      notification.open({
        message: "Error!.",
        description: `Please try after some time.`,
        placement: "topRight",
        duration : 2,
        // icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      });
    }
  };

  const validateForm = (formData) => {
    try {
      if (
        formData.get("companyName") === "undefined" ||
        formData.get("companyName") === null ||
        formData.get("companyName").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          companyName : "Please select Company name."
        }));
        return false;
      } else if(
        formData.get("taxRegistrationNumber") === "undefined" ||
        formData.get("taxRegistrationNumber") === null ||
        formData.get("taxRegistrationNumber").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          taxRegistrationNumber : "Please select Tax Registration Number."
        }));
        return false;
      } else if(
        formData.get("supportPhoneNumber") === "undefined" ||
        formData.get("supportPhoneNumber") === null ||
        formData.get("supportPhoneNumber").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          supportPhoneNumber : "Please select Support Phone Number."
        }));
        return false;
      } else if(
        formData.get("supportEmailId") === "undefined" ||
        formData.get("supportEmailId") === null ||
        formData.get("supportEmailId").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          supportEmailId : "Please select Support Email id."
        }));
        return false;
      } else if(
        formData.get("bankName") === "undefined" ||
        formData.get("bankName") === null ||
        formData.get("bankName").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          bankName : "Please enter bank name."
        }));
        return false;
      } else if(
        formData.get("accountNumber") === "undefined" ||
        formData.get("accountNumber") === null ||
        formData.get("accountNumber").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          accountNumber : "Please enter Account Number."
        }));
        return false;
      } else if(
        formData.get("openingHours") === "undefined" ||
        formData.get("openingHours") === null ||
        formData.get("openingHours").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          openingHours : "Please enter Opening Hours."
        }));
        return false;
      } else if(
        formData.get("closingHours") === "undefined" ||
        formData.get("closingHours") === null ||
        formData.get("closingHours").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          closingHours : "Please enter Closing hours."
        }));
        return false;
      }
      else{
        return true;
      }
    } catch (error) {
      setErrors((pre)=>({
        ...pre,
        formError : "Server is under maintenance."
      }));
      return false
    }
  };

  const handleChange = (e) =>{
    const {name, value} = e.target;
    setErrors((pre)=>({
      ...pre,
      [name] : ""
    }));
  }
  
  return (
    <section className="vh-100">
      <div className="container-fluid">
        <div className="row ">
          <div
            className="col-sm-6  text-black frisbee_dashboard_signup_col_section  scrollable_content_signup_left_side"
          >
            <div className="h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5 frisbee_logo_sign_up_main_section">
              <div>
                <img src={frisbeeLogo} alt="frisbee_logo" />
              </div>
              <form className="sign_up_form_main_class" onSubmit={handleSubmit}>
                <h4>Fill the details bellow</h4>
                <div className="row sign_up_vendor_first_name">
                  <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="companyName">Company Name</label>
                    <input
                      title="Enter your company name."
                      type="text"
                      name="companyName"
                      id="companyName"
                      className="form-control store_input_field_field"
                      onChange={handleChange}
                      placeholder="Enter your company name"
                    />
                    {errors?.companyName?(<p style={{color:'red'}}>{errors?.companyName}</p>):null}
                  </div>

                  <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="company_registration_document">
                      Company Registration Document
                    </label>
                    <div
                      className="sign_up_file_upload"
                      id="company_registration_document"
                    >
                      <div>{companyDetails?.name?companyDetails?.name:'Upload File'}</div>
                      <Upload id="company_registration_document" onChange={handleFile1} showUploadList={false} >
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      </Upload>
                    </div>
                    {errors?.companyRegistrationDocument?(<p style={{color:'red'}}>{errors?.companyRegistrationDocument}</p>):null}
                  </div>
                </div>

                {/* //Next line */}
                <div className="row sign_up_vendor_first_name">
                  <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="taxRegistrationNumber">
                      Tax Registration number
                    </label>
                    <input
                      title="Enter tax registration number."
                      type="text"
                      className="form-control store_input_field_field"
                      name = "taxRegistrationNumber"
                      id="taxRegistrationNumber"
                      placeholder="Enter tax registration number."
                      onChange={handleChange}
                    />
                    {errors?.taxRegistrationNumber?(<p style={{color:'red'}}>{errors?.taxRegistrationNumber}</p>):null}
                  </div>

                  <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="supportPhoneNumber">
                      Support phone number
                    </label>
                    <input
                      title="Enter your last name."
                      type="number"
                      name="supportPhoneNumber"
                      className="form-control store_input_field_field"
                      id="supportPhoneNumber"
                      placeholder="Jamie"
                      onChange={handleChange}
                    />
                    {errors?.supportPhoneNumber?(<p style={{color:'red'}}>{errors?.supportPhoneNumber}</p>):null}
                  </div>
                </div>

                <div className="sign_up_vendor_first_name">
                  <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="support_email_id">Support Email ID</label>
                    <input
                      title="Enter your email address."
                      type="text"
                      name="supportEmailId"
                      className="form-control store_input_field_field"
                      id="supportEmailId"
                      placeholder="Enter your email address."
                      onChange={handleChange}
                    />
                    {errors?.supportEmailId?(<p style={{color:'red'}}>{errors?.supportEmailId}</p>):null}
                  </div>
                </div>

                {/* //Next line */}
                <div className="row sign_up_vendor_first_name">
                  <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="bankName">Bank name</label>
                    <input
                      title="Enter your bank name."
                      type="text"
                      className="form-control store_input_field_field"
                      id="bankName"
                      name="bankName"
                      placeholder="Enter bank name"
                      onChange={handleChange}
                    />
                    {errors?.bankName?(<p style={{color:'red'}}>{errors?.bankName}</p>):null}
                  </div>

                  <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="accountNumber">Account number</label>
                    <input
                      title="Enter account number."
                      type="number"
                      name="accountNumber"
                      className="form-control store_input_field_field"
                      id="accountNumber"
                      placeholder="Enter account number."
                      onChange={handleChange}
                    />
                    {errors?.accountNumber?(<p style={{color:'red'}}>{errors?.accountNumber}</p>):null}
                  </div>
                </div>

                <div className="sign_up_vendor_first_name">
                  <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="product_details_with_Images">
                      Product details with Images and prices in Excel
                    </label>
                    <div
                      className="sign_up_file_upload"
                      id="company_registration_document"
                    >
                      <div>{productDetails?.name?productDetails?.name:'Upload File'}</div>
                      <Upload id="company_registration_document" onChange={handleFile2} showUploadList={false}>
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      </Upload>
                    </div>
                    {errors?.productDetailsDocument?(<p style={{color:'red'}}>{errors?.productDetailsDocument}</p>):null}
                  </div>
                </div>

                <div className="sign_up_vendor_first_name">
                  <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="product_details_with_Images">
                      Opening and closing hours
                    </label>
                    <div className="row sign_up_vendor_square_all_timer_section">
                      <div className="col-md-6 sign_up_vendor_square_box_main_section ">
                        <h6>Open</h6>
                        <input
                          type="time"
                          name = "openingHours"
                          id = "openingHours"
                          className="form-control store_input_field_field"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-6 sign_up_vendor_square_box_main_section">
                        <h6>Close</h6>
                        <input
                          type="time"
                          name = "closingHours"
                          id = "closingHours"
                          className="form-control store_input_field_field"
                          onChange={handleChange}
                        />
                      </div>
                      {errors?.openingHours?(<p style={{color:'red'}}>{errors?.openingHours}</p>):null}
                      {errors?.closingHours?(<p style={{color:'red'}}>{errors?.closingHours}</p>):null}
                    </div>
                  </div>
                </div>

                <div className="sign_up_button_main_section_submit">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-6 px-0 d-none d-sm-block sign_up_class_for_img">
            <img src={signUp} alt="Login image" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
