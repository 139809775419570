import { API_VENDOR_URL } from "../../config/constants";
import { postRequest, fetchIpAddress } from "../API";

/*********************************************************
* Function Name : List
* Description   : Get list of all service
* By            : Afsar Ali
* Date          : 28-03-2024 
*********************************************************/
export const list = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}services/list`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count : res?.data?.response?.count};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage, count : 0}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : changeStatus
* Description   : change service status
* By            : Afsar Ali
* Date          : 28-03-2024 
*********************************************************/
export const changeStatus = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}services/chnage-status`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage, count : 0}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : serviceList
* Description   : Get service list of vendor
* By            : Afsar Ali
* Date          : 14-05-2024 
*********************************************************/
export const serviceList = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}categories/service-categories`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count : res?.data?.response?.count};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage, count : 0}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function