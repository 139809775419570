import React, { useState, useEffect } from "react";
import { TbCategory } from "react-icons/tb";
import { FaPlusSquare } from "react-icons/fa";

import { Link } from "react-router-dom";
import moment from "moment";

import { Select } from "antd";
import Categories1 from "../../../Image/categories1.png";
import Categories2 from "../../../Image/categories2.png";
import Categories3 from "../../../Image/categories3.png";
import Categories4 from "../../../Image/categories4.png";
import { Switch } from "antd";
import Categories5 from "../../../Image/categories6.png";
import CategoriesOpenPopUp from "../CategoriesOpenPopUp";
import TopNavBar  from "../../DashBaord/Top_navbar";
import "../Categories.css";

import { BASE_URL } from "../../../config/constants";
import { getPage } from "../../../controllers/common";
import { servicetlist } from "../../../controllers/categories";
import {ASSETS_BASE_URL} from "../../../config/constants";

const Categories = () => {
  // delete PopUp
  const [categoriesPopUp, setPopUpOpen] = useState(false);
  const [shortItem, setShortItem] = useState([
    {
      value: "all",
      label: "All",
    },
    {
      value: "A",
      label: "Active",
    },
    {
      value: "I",     
      label: "Inactive",            
    },
  ]);
  const togglePopUp = () => {
    setPopUpOpen(!categoriesPopUp);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from : "",
    to : "",
    filter_by : "",
    search : ""
  })//End

  /*********************************************************
  *  This function is use to fetch category list
  *********************************************************/
  const getList = async ()=>{
    const options = {
      type : "",
      condition : { 
        ...(filter?.from ? {
          createdAt: {
            '$gte': moment(`${filter?.from?.$y}-${filter?.from?.$M + 1}-${filter?.from?.$D}`).format('YYYY-MM-DD'),
            '$lte': moment(`${filter?.to?.$y}-0${filter?.to?.$M + 1}-${filter?.to?.$D + 1}`).format('YYYY-MM-DD'),
          }
        } : null),
        ...(filter?.search?{[filter?.filter_by]:{ $regex: filter.search, $options: 'i' }}:null)
      },
      select    : { },
      sort      : {"_id" : -1},
      skip      : SKIP?SKIP:0,
      limit     : LIMIT?LIMIT:10
    }
    const listData = await servicetlist(options);
    // console.log('listData',listData); 
    setListData(listData?.result);

    const countOptions = {
      type : "count",
      condition : { }
    }
    const count = await servicetlist(countOptions);
    setTotalPages(getPage(count?.result));
  };//End
 
  /*********************************************************
  *  This function is load when page load and with dependency update
  *********************************************************/
  useEffect(()=>{
    getList();
  },[currentPage]);
// console.log('ALLLISTDATA',ALLLISTDATA);
  return (
    <>
      <div className="container-fluid">
        <TopNavBar title={`Service Categories`}></TopNavBar>
        <div className="order_now_card_tilte">
          <div class="card">
            <div class="card-body categories_main_div">
              <div className="categories_tb_select">
                <TbCategory className="categories_tb_select_svg"/>
                <Select
                  defaultValue="All"
                  style={{
                    width: 120,
                  }}
                  className="payment_select_tag_rating_review"
                  bordered={false}
                  options={shortItem}
                />
              </div>
              <div className="promotions_filters_div">
                {/* <div className="fa_filter_rating_review">
                  <img
                    src={`${BASE_URL}./images/Filter.svg`}
                    alt=""
                    className="user_iconses"
                  />

                  <Link to={false} className="filter_btn_invontry_rating_review">
                    Filters
                  </Link>
                </div> */}
                <div>
                  <button
                    type="button"
                    class="btn btn-dark"
                    onClick={togglePopUp}
                  >
                    <FaPlusSquare className="Promotions_faPlus_Squares" />
                    <span className="request_new_categories">Request New</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //Card section */}

        <div class="row">
        {ALLLISTDATA && (
            ALLLISTDATA.map((items, index)=>(<>
            <div class="col-md-6 col-lg-4">
              <div class="card-box categories_card_box_for_pet">
                <div class="card-thumbnail vendor_card_services_img">
                  <img src={`${ASSETS_BASE_URL}${items.image}`} class="img-fluid" alt="" />
                </div>
                <h3>
                  <span className="cotegories_pet_food">{items.name}</span>
                </h3>
                <p className="cotegories_about_food_description">{items.details}
                </p>
                <div className="catogries_create_status">
                  <div>
                    <span className="cartegories_created_on">Created on</span>
                  </div>
                  <div>
                    <span className="cartegories_created_on_date">
                    {moment(`${items.createdAt}`).format('DD/MM/YYYY')}
                    </span>
                  </div>
                </div>
                <div className="catogries_create__switch">
                  <div>
                    <span className="cartegories_created_on">Status</span>
                  </div>
                  <div>
                    <span className="cartegories_created_on_date">
                      <Switch 
                        className="categories_switch_"
                        checked={items.status === "A"?true:false} 
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            </>)))}
        </div>
        <CategoriesOpenPopUp
          categoriesPopUp={categoriesPopUp}
          togglePopUp={togglePopUp}
          type="Service"
          title="Request Service Category"
        />
      </div>
    </>
  );
}

export default Categories;
