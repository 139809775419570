import React, { useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { FaDesktop, FaArrowLeft } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addEditData, deleteProductVariant, getlist } from "../../../controllers/Products/product";
import { notification, Switch, Upload } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import ProductListDeletePopUp from "./ProductListDeletePopUp";
import { fetchIpAddress } from "../../../controllers/API";

function ProductListEditPage() {
  const navigate = useNavigate();
  const data = useLocation().state;
  const [dataForm, setFormData] = useState({});
  const filteredVariants = data?.varients?.filter(variant => variant.status !== 'D');
// by md
  const [variants, setVariants] = useState(filteredVariants?filteredVariants :[
    { unit: "", qty: "", rate: "", discountType: "",discount:"" },
  ]);
  const addMoreVariants = () => {
    setVariants([...variants, { unit: "", qty: "", rate: "", discountType: "",discount:"" }]);};

  const handleVariantChange = (index, property, value) => {
    const newVariants = [...variants];
    newVariants[index][property] = value;
    setVariants(newVariants);
  };
  const handleVariantDelete = async (e, indexToRemove, variant) => {
    e.preventDefault();  
    const params = {
      url: `productsmaster/delete-variant`,
      postData: {
        varient_id: variant._id
      }
    };
    try {
      const res = await deleteProductVariant(params);
      if (res.status === true) {
      setVariants(prevVariants =>
      prevVariants.filter((variant, index) => index !== indexToRemove)
    );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  // end
  const { Dragger } = Upload;
  const [error, setError] = useState([]);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [imageList, setImageList] = useState("");
  const [allProducts, setAllProduct] = useState([]);
  const [productId, setProductId] = useState(data?.productData._id ?? "");
  const [productName, setProductName] = useState('');
  const [isAddPage, setIsAddPage] = useState(data ? false : true);

  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  /*********************************************************
   *  This function is use to handle success notification show
   *********************************************************/
  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data Saves Successfully .",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  }; //End
  /*********************************************************
   *  This function is use to handle error notification show
   *********************************************************/
  const handleErrorMsg = () => {
    notification.open({
      message: "Opps!",
      description: error?.formError,
      placement: "topRight",
      icon: <CloseOutlined twoToneColor="#fc2339" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  }; //End

  /*********************************************************
   *  This function is use to handle cancle button and close popup
   *********************************************************/
  const handleCancel = () => {
    setFormData("");
    setImageList("");
  };
  /*********************************************************
   *  This function is use to handle image object
   *********************************************************/
  const handleFileChange = (e) => {
    setImageList(e.fileList[0].originFileObj);
  };
  // *  This function is use to validate form data before submit
  // *********************************************************/
  const validateFormData = (formData) => {
    if (!formData.product_name || formData.product_name === "") {
      setError((prevError) => ({
        ...prevError,
        name: "Product name is required",
      }));
      return false;
    }
    if ( formData.varients[0].rate === '' && formData.varients[0].qty==='' && formData.varients[0].discount===''&& formData.varients[0].unit==='') {
      setError((prevError) => ({
        ...prevError,
        varients: "Variants is required",
      }));
      return false;
    }else{
      return true;
    }


  };
  

  /*********************************************************
   *  This function is use to fetch category list
   *********************************************************/
  const getAllActiveProductList = async () => {
    const options = {
      type: "",
      condition: { status: "A" },
      select: { name: true },
      sort: { name: 1 },
      populate: { key: "", select: "" },
    };
    const listData = await getlist(options);
    if (listData.status === true) {
      setAllProduct(listData?.result);
    } else {
      setAllProduct([]);
    }
  }; //End


  /*********************************************************
   *  This function is use to handle input chnage and set in state
   *********************************************************/
  const handleChange = (e) => {
    if (e.target.name === "product") {
      const value = e.target.value
      const product = allProducts.find(product => product._id === value);

      setProductId(product._id);
      setProductName(product.name)
      setError("");
    } else {
      setFormData((pre) => ({
        ...pre,
        [e.target.name]: e.target.value,
      }));

      setError((pre) => ({
        ...pre,
        [e.target.name]: "",
      }));
    }
  }; //End

  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Fetch the IP address asynchronously
      const ipAddress = await fetchIpAddress();
  const dataForm={
    "product_name": data?data.productData.name:productName || "",
    "productid"   : data?data.productData._id:productId|| "",
    "varients"    :  variants || "",
    "ipAddress": ipAddress
  }
  const dataFormEdit ={
    "edit_id"     : data?._id,
    "product_name": data?data.productData.name:productName || "",
    "productid"   : data?data.productData._id:productId|| "",
    "varients"    :  variants || "",
    "ipAddress": ipAddress
  } 
      // Validate form data
      const isValidate = validateFormData(dataForm);
  
      if (isValidate) {
        const params = {
          url: `productsmaster/create`,
          postData: dataForm,
        };
        const paramsForEdit={
          url:`productsmaster/edit`,
          postData:dataFormEdit,
        }
  
        // Perform API call to create product
        const res = await addEditData(isAddPage?params:paramsForEdit);
  
        if (res.status === true) {

  
          if (isAddPage === true) {
            setFormData("");
            setImageList("");
            handleSuccessMsg();
            handleCancel();
          }
          navigate('/product/list')
        } else {
          setError((prev) => ({
            ...prev,
            formError: res?.message,
          }));
  
          handleErrorMsg();
          setFormData("");
          setImageList("");
          handleCancel();
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      // Handle error appropriately
    }
  };
  
  useEffect(() => {
    getAllActiveProductList();
  }, []);

  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <div>
            <h5 className="inventory_edit_page_heading">
              {isAddPage ? "Add" : "Edit"} Product{" "}
            </h5>
          </div>
          <Link to={`/product/list`}>
            <div>
              <button type="button" className="btn btn-secondary">
                <FaArrowLeft /> Back
              </button>
            </div>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-header py-3 invetory_title_div">
            <div>
              <h6 className="m-0 font-weight-bold text-primary"></h6>
            </div>
            {isAddPage ? (
              ""
            ) : (
              <div>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={togglePopUp}
                >
                  Delete Data
                </button>
              </div>
            )}
          </div>
          <div className="card-body">
            <div className="responsive">
              <form action="" className="inventory_form_main_contnet">
                <div class="form-group">
                  <div class="form-group">
                    <label
                      for="categoryId"
                      className="categories_open_popup_label_name"
                    >
                      Product Name
                    </label>
                    <select
                      value={productId || ""}
                      name="product"
                      id="categoryId"
                      className="form-control categories_select_tg_enter_product"
                      onChange={handleChange}
                    >
                      {isAddPage ? (
                        <option value="">Select Product</option>
                      ) : (
                        ""
                      )}
                      {allProducts &&
                        allProducts.map((item, index) => (
                          <option
                            key={index}
                            value={item._id}
                            selected={
                              productId === item?._id ? true : false
                            }
                          >
                            {" "}
                            {item?.name}
                          </option>
                        ))}
                    </select>
                    {error?.name ? (
                      <p style={{ color: "red" }}>{error.name}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div>
                  {variants.map((variant, index) => (
                    <div className="row mt-3" key={index}>
                      <div className="col-2 form-group">
                        <input
                          type="text"
                          placeholder="Unit"
                          value={variant.unit}
                          className="form-control store_input_field_field"
                          onChange={(e) =>
                            handleVariantChange(index, "unit", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-2 form-group">
                        <input
                          type="text"
                          className="form-control store_input_field_field"
                          placeholder="Qty"
                          value={variant.qty}
                          onChange={(e) =>
                            handleVariantChange(index, "qty", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-2 form-group">
                        <input
                          type="text"
                          className="form-control store_input_field_field"
                          placeholder="Rate"
                          value={variant.rate}
                          onChange={(e) =>
                            handleVariantChange(index, "rate", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-2 form-group">
                        <input
                          type="text"
                          className="form-control store_input_field_field"
                          placeholder="discountType"
                          value={variant.discountType}
                          onChange={(e) =>
                            handleVariantChange(index, "discountType", e.target.value)
                          }
                        />
                        
                      </div>
                      <div className="col-2 form-group">
                        <input
                          type="text"
                          className="form-control store_input_field_field"
                          placeholder="discount"
                          value={variant.discount}
                          onChange={(e) =>
                            handleVariantChange(index, "discount", e.target.value)
                          }
                        />
                        
                      </div>
                      <div className="col-2 form-group">
                        <button className="btn-checkk mt-2" onClick={(e) => handleVariantDelete(e,index,variant)}>Delete</button>
                      </div>
                    </div>
                  ))}
                       {error?.varients ? (
                      <p style={{ color: "red" }}>{error.varients}</p>
                    ) : (
                      ""
                    )}
                </div>
                <button
                  type="button"
                  onClick={addMoreVariants}
                  className="btn-add-more"
                >
                  Add More Variants
                </button>
                {/* <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Images</label>
                  <Dragger
                    className="about_us_dragger_file"
                    onChange={handleFileChange}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload. Strictly prohibited
                      from uploading company data or other banned files.
                    </p>
                  </Dragger>
                </div> */}
                <div className="inventory_edit_submit_btn">
                  <button
                    type="button"
                    className="btn btn-primary inventory_save_button"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ProductListDeletePopUp
        popUpOpen={popUpOpen}
        EDITDATA={data}
        togglePopUp={togglePopUp}
      />
    </div>
  );
}

export default ProductListEditPage;
