import React, { useState, useEffect, useRef } from "react";
import { FaFilter } from "react-icons/fa";
import { Dropdown, Menu, Switch, Flex, Spin } from "antd";
import Pagination from "@mui/material/Pagination";
import { Link, useNavigate } from "react-router-dom";

import { FaPlusSquare } from "react-icons/fa";
import ServicesRemovePopUp from "./ServicesDeletePopup";

import AddRequestPopUp from "./AddRequestPopUp";

import moment from "moment";
import { ASSETS_BASE_URL } from "../../../config/constants";
import SkeltonList from "../../../components/SkeltonEffect/list";
import { list, changeStatus } from "../../../controllers/services/servicesController";
import { getPage, statusMessage } from '../../../controllers/common';
const List = ({}) => {
    //remove or delete popup
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // Open Add Request PopUp
  const [requestAddPopUp, setRequestAddPopUp] = useState(false);
  const requestTogglePopUp = () => {
    setRequestAddPopUp(!requestAddPopUp);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

/************************************************************************************* */
const navigate = useNavigate();
const targetRef = useRef(null);
const [isLoading, setIsLoading] = useState(false);
const [currentPage, setCurrentPage] = useState(1);
const [ALLLISTDATA, setListData] = useState([]);
const [TOTALPAGES, setTotalPages] = useState(1);
const [LIMIT, setLimit] = useState(10);
const [SKIP, setSkip] = useState(0);
const [filter, setFilter] = useState({
  from : '',
  to : new Date(),
  filter_by : "",
  search : ""
})//End

const [showRequest, setShowRequest] = useState("");


/*********************************************************
*  This function is use to fetch vendor list
*********************************************************/
const getList = async ()=>{
  setListData([]);
  const options = {
    type : "",
    condition : { 
      ...(filter?.from ? {
        createdAt: {
          '$gte': moment(filter?.from).format('YYYY-MM-DD'),
          '$lte': moment().format('YYYY-MM-DD'),

          // '$gte': moment(`${filter?.from?.$y}-${filter?.from?.$M + 1}-${filter?.from?.$D}`).format('YYYY-MM-DD'),
          // '$lte': moment(`${filter?.to?.$y}-0${filter?.to?.$M + 1}-${filter?.to?.$D + 1}`).format('YYYY-MM-DD'),
        },
      } : null),
      ...(filter?.search?{[filter?.filter_by]:{ $regex: filter.search, $options: 'i' }}:null),
      ...(showRequest?{status : showRequest}:null)
    },
    select    : { },
    sort      : {"_id" : -1},
    skip      : SKIP?SKIP:0,
    limit     : LIMIT?LIMIT:10
  }
  const listData = await list(options);
  if(listData.status === true){
    if(listData.result.length > 0){
      setListData(listData?.result);
      setTotalPages(getPage(listData?.count)); 
    } else{
      setListData([{vendor_first_name : 'No Data Found'}]);
      setTotalPages(1);  
    }
    
  }else{
    setListData([{vendor_first_name : 'No Data Found'}]);
    setTotalPages(1);
  }
};//End

/*********************************************************
*  This function is for handle page change
*********************************************************/
const handlePageChange = (event, newPage) => {
  setCurrentPage(newPage);
  setSkip((newPage -1) * LIMIT);
  targetRef.current.scrollIntoView({
    behavior: 'smooth',
  });
};

/*********************************************************
*  This function is load when page load and with dependency update
*********************************************************/
useEffect(()=>{
  getList();
  document.title = 'Frisbee Vendor Admin || Services List'
},[currentPage,showRequest, isLoading]);

  const handleStatusChnage = async (item) =>{
    try {
      setIsLoading(true);
      const status = item?.status === 'A'?"I":"A";
      const options = {
        id : item._id,
        status : status
      }
      const res = await changeStatus(options);
      if(res?.status === true){
        setIsLoading(false);
      }else{
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }
  return (
    <div className="MuiBox-root css-19kzrtu">
        <div id="content-wrapper" className="d-flex flex-column">
            <div className="card shadow mb-4">
            <div className="card-header py-3 inventory_vendor_add_new_btn">
                <h6 className="m-0 font-weight-bold common_inventory_heading">
                 Grooming List
                </h6>
                <div>
                <button
                    type="button"
                    class="btn btn-dark"
                    onClick={requestTogglePopUp}
                >
                    <FaPlusSquare className="Promotions_faPlus_Squares" />
                    <span className="request_new_categories">
                    Request New
                    </span>
                </button>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="table-responsive">
                <table
                    className="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellspacing="0"
                >
                    <thead>
                    <tr>
                        <th>Image</th>
                        <th>Service Name</th>
                        {/* <th>Store</th> */}
                        <th>Branch</th>
                        <th>Category</th>
                        {/* <th>Sub Category</th> */}
                        <th>Price</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ALLLISTDATA?.length > 0 ? (ALLLISTDATA.map((items, index)=>(<>
                    <tr>
                        <td>
                        {" "}
                        {items?.image?(<>
                            <img src={ ASSETS_BASE_URL + items?.image } alt="" width="100px"/>
                        </>):(<>
                            <img src={"../images/IntentoryTableLogo.png" } alt="" />
                        </>)}
                        
                        </td>
                        <td>{items?.name} </td>
                        <td>
                            <span>{items?.branch?.branch_name}</span><br/>
                            <span>{items?.branch?.phone}</span><br/>
                            <span>{items?.branch?.email}</span><br/>
                            <span>{items?.branch?.city}</span>
                        </td>
                        {/* <td>
                            <span>{items?.store?.shop_name}</span><br/>
                            <span>{items?.store?.phone}</span><br/>
                            <span>{items?.store?.email}</span><br/>
                            <span>{items?.store?.city}</span>
                        </td> */}
                        <td>{items?.category?.name}</td>
                        {/* <td>{items?.subcategory?.name}</td> */}
                        <td>AED {items?.price}</td>
                        <td>
                        <Switch 
                            className="categories_switch_" 
                            checked={items?.status === 'A'?true:false}
                            onClick={()=>handleStatusChnage(items)}
                        />
                        </td>
                    </tr>
                    </>))) : (<SkeltonList row={5} col={8} />)}
                    </tbody>
                </table>
                <div className="table_pagination_sales_order">
                    <Pagination count={TOTALPAGES} onChange={handlePageChange} />
                </div>
                </div>
            </div>
            </div>
        </div>
        <AddRequestPopUp
          requestAddPopUp={requestAddPopUp}
          requestTogglePopUp={requestTogglePopUp}
        />
        <ServicesRemovePopUp popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        <Flex align="center" gap="middle">
          <Spin 
            size="large" 
            spinning={isLoading}
            fullscreen={true}
          />
        </Flex>
    </div>
  );
};

export default List;
