import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { Dropdown, Menu } from "antd";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { Link } from "react-router-dom";
import { Select } from "antd";
import EnquiryDeletePopUp from "./EnquiryDeletePopup";
import "../Orders/OrderMainContnet.css";
import EnquiryTopNavbar from "./EnquiryTopNavBar";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoDocumentTextSharp } from "react-icons/io5";

function EnquiryList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

EnquiryList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  // delete PopUp
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/enquiryDetails">
          <IoDocumentTextSharp />
          <span className="show_span_edit">Enquiry</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={togglePopUp}>
        <MdDelete /> <span className="show_span">Delete</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="container-fluid">
        <EnquiryTopNavbar></EnquiryTopNavbar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value_rating_review">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                    />
                  </Tabs>
                </div>

                <div className="filters_inventory_Rating_review">
                  {/* <div className="fa_filter_rating_review">
                    <img
                      src={process.env.PUBLIC_URL + "./images/Filter.svg"}
                      alt=""
                      className="user_iconses"
                    />

                    <a href="#" className="filter_btn_invontry_rating_review">
                      Filters
                    </a>
                  </div> */}
                  {/* <div>
                    <Select
                      defaultValue="This Months"
                      style={{
                        width: 120,
                      }}
                      className="payment_select_tag_rating_review"
                      bordered={false}
                      options={[
                        {
                          value: "XYZ",
                          label: "XYZ",
                        },
                        {
                          value: "ABC",
                          label: "ABC",
                        },
                        {
                          value: "CDE",
                          label: "CDE",
                        },
                      ]}
                    />
                  </div> */}
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <EnquiryList
              value={value}
              index={0}
              className="main_container_payments"
            >
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 all_border_bottom_for_hide">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        Recent Enquiries
                      </h6>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Enquiry Id</th>
                              <th>Date</th>
                              <th>Listing Id</th>
                              <th>Contact</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>#23638</td>
                              <td>22/05/2023, 08:24 pm</td>
                              <td>#23638</td>
                              <td>+91-9435-04342</td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td>#23638</td>
                              <td>22/05/2023, 08:24 pm</td>
                              <td>#23638</td>
                              <td>+91-9435-0455</td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td>#23638</td>
                              <td>22/05/2020, 09:24 pm</td>
                              <td>#23638</td>
                              <td>+91-9435-04342</td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td>#23638</td>
                              <td>22/05/2021, 08:24 pm</td>
                              <td>#23638</td>
                              <td>+91-9435-04342</td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td>#23638</td>
                              <td>22/05/2010, 08:24 pm</td>
                              <td>#23638</td>
                              <td>+91-9435-04342</td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td>#23638</td>
                              <td>22/05/2023, 08:24 pm</td>
                              <td>#23638</td>
                              <td>+91-9435-04342</td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={50} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </EnquiryList>
          </Box>
        </div>
        <EnquiryDeletePopUp popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
      </div>
    </>
  );
}
