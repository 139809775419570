import React, {useState,useEffect} from "react";
import "./setting.css";
import { IoLocationOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { CiClock2 } from "react-icons/ci";
import { Link } from "react-router-dom";
import { MdRemoveRedEye } from "react-icons/md";
import { ASSETS_BASE_URL } from "../../config/constants";
import Top_navbar from "../DashBaord/Top_navbar";


const Setting = () => {
  const INFO = JSON.parse(sessionStorage.getItem('ADMIN-INFO'));
  const [VENDORINFO, SetVendorInfo] = useState(INFO || {});
  const [showAccountNo, setShowAccountNo] = useState(false);

  return (
    <div>
      <Top_navbar title={"Setting"} />
      <div className="container-fluid">
        <div className="row">
          <div class="col-12">
            
            <div class="card user_profile_section">
              <div className="user_profile_main_class">
                <div>
                  <img
                    src={VENDORINFO.vendor_image ?  ASSETS_BASE_URL + VENDORINFO.vendor_image : "https://frisbee-llc.s3.me-central-1.amazonaws.com/images/favicon.png"}
                    alt=""
                    className="user_profile_pic"
                    width="80px"
                  />
                </div>
                <div class="card-body">
                  {" "}
                  <span className="profile_user_name" >{VENDORINFO.vendor_first_name} {VENDORINFO.vendor_last_name}</span>{" "}
                  <br />
                  <span className="profile_user_admin">{VENDORINFO.user_type}</span>
                </div>
              </div>
            </div>


            {/* store details */}
            <div className="card profile_store_details">
              <div className="card-body">
                <div className="profile_store_details_for_edit">
                  <div>
                    <span className="orders_now_billing_details_heading">
                      Store Appointment Configurations
                    </span>
                  </div>
                </div>
                <table className="table table-borderless profile_store_table">
                  <tbody>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <div className="profile_details_section">
                          <IoLocationOutline />{" "}
                          <span className="profile_details_location">
                            Location
                          </span>
                        </div>
                      </td>
                      <td class="text-end order_now_rupees">
                        <span className="profile_store_location">
                          {VENDORINFO.vendor_country} {VENDORINFO.vendor_state}
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location1">
                          {VENDORINFO.vendor_address} {VENDORINFO.vendor_city}
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <IoCallOutline />{" "}
                        <span className="profile_details_location">
                          Contact Number
                        </span>
                      </td>
                      <td class="text-end order_now_rupees">
                        {" "}
                        <span className="profile_store_location">
                          {VENDORINFO.vendor_phone}
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <CiMail />{" "}
                        <span className="profile_details_location">E-Mail</span>
                      </td>
                      <td class="text-end order_now_rupees">
                        {" "}
                        <span className="profile_store_location">
                          {VENDORINFO.vendor_email}
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <CiClock2 />{" "}
                        <span className="profile_details_location">
                          Store Timings
                        </span>
                      </td>
                      <td class="text-end order_now_rupees">
                        <span className="profile_store_location">
                          Monday 10:00 - 22:00
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location">
                          Tuesday 10:00 - 22:00
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location">
                          Wednesday 10:00 - 22:00
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location">
                          Thursday 10:00 - 22:00
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location">
                          Friday 10:00 - 22:00
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location">
                          Saturday 10:00 - 22:00
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location">
                          Sunday 10:00 - 22:00
                        </span>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* // Bank Details */}
            <div className="card profile_store_details">
              <div className="card-body">
                <div className="profile_store_details_for_edit">
                  <div>
                    <span className="orders_now_billing_details_heading">
                      Bank Details
                    </span>
                  </div>
                  {/* <div>
                    <button type="button" class="btn btn-dark">
                      Add Bank Details
                    </button>
                  </div> */}
                </div>
                <table className="table table-borderless Bank_details_profile_store_table">
                  <tbody>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <div className="profile_details_section">
                          <span className="profile_details_location">
                            Card Holder
                          </span>
                        </div>
                      </td>
                      <td class="text-end order_now_rupees">
                        <span className="profile_store_location1">
                          Eleanor Patel
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <span className="profile_details_location">
                          Account Number
                        </span>
                      </td>
                      <td class="text-end order_now_rupees">
                      <span className="profile_store_location1">
                          {showAccountNo===true?VENDORINFO.vendor_account_number:'xxxxxxxxxxx'}
                        </span>{" "}
                      </td>
                      <span
                          className="input-group-text my_eye_icon"
                          onClick={()=>setShowAccountNo(!showAccountNo)}
                        >
                          {showAccountNo ? (
                            <i className="bi bi-eye-slash-fill">
                              <MdRemoveRedEye />
                            </i>
                          ) : (
                            <i className="bi bi-eye-fill">
                              <MdRemoveRedEye />
                            </i>
                          )}
                        </span>
                    </tr>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <span className="profile_details_location">
                          Account Type
                        </span>
                      </td>
                      <td class="text-end order_now_rupees">
                        <span className="profile_store_location1">
                         {VENDORINFO.vendor_account_type}
                        </span>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Setting;
