import { useState } from 'react';
import { db } from '../../../../../config/firebaseConfig';
import './addUser.css';
import { arrayUnion, collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { useUserStore } from '../../../../../lib/userStore';

const AddUser = () => {
  const [user, setUser] = useState(null);
  const { currentUser } = useUserStore();

  const handleSearch = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const username = formData.get("username");

    try {
      const userRef = collection(db, "users");
      const q = query(userRef, where("username", "==", username));
      const querySnapShot = await getDocs(q);

      if (!querySnapShot.empty) {
        const foundUser = querySnapShot.docs[0].data();
        foundUser.id = querySnapShot.docs[0].id; // Ensure id is set
        console.log('Found user:', foundUser); // Debug log
        setUser(foundUser);
      } else {
        console.log('No user found with username:', username); // Debug log
        setUser(null);
      }
    } catch (error) {
      console.log('Error searching for user:', error); // Debug log
    }
  };

  const handleAdd = async () => {
  if (!user) {
    console.error("No user selected");
    return;
  }

  const userId = user.id;
  const currentUserId = currentUser?.id;

  // Debug logs for user and currentUser
  console.log('Selected user:', user);
  console.log('Current user:', currentUser);

  if (!userId || !currentUserId) {
    console.error("User ID or Current User ID is undefined");
    return;
  }

  const chatRef = collection(db, "chats");
  const userChatsRef = collection(db, "userChats");

  try {
    const newChatRef = doc(chatRef);
    await setDoc(newChatRef, {
      createdAt: serverTimestamp(),
      messages: [],
    });

    console.log("Adding chat to userChats with IDs:", userId, currentUserId);

    // Check if user chats document exists for the user
    const userDocRef = doc(userChatsRef, userId);
    const userDocSnap = await getDoc(userDocRef);
    if (!userDocSnap.exists()) {
      await setDoc(userDocRef, {
        chats: []
      });
    }

    // Check if user chats document exists for the current user
    const currentUserDocRef = doc(userChatsRef, currentUserId);
    const currentUserDocSnap = await getDoc(currentUserDocRef);
    if (!currentUserDocSnap.exists()) {
      await setDoc(currentUserDocRef, {
        chats: []
      });
    }

    await updateDoc(userDocRef, {
      chats: arrayUnion({
        chatId: newChatRef.id,
        lastMessage: "",
        receiverId: currentUserId,
        updatedAt: Date.now(),
      })
    });

    await updateDoc(currentUserDocRef, {
      chats: arrayUnion({
        chatId: newChatRef.id,
        lastMessage: "",
        receiverId: userId,
        updatedAt: Date.now(),
      })
    });

    console.log('New chat ID:', newChatRef.id); // Debug log
  } catch (error) {
    console.log('Error adding chat:', error); // Debug log
  }
};


  return (
    <div className='addUser'>
      <form onSubmit={handleSearch}>
        <input type="text" placeholder='Username' name='username' />
        <button>Search</button>
      </form>
      {user && (
        <div className="user">
          <div className="detail">
            <img src={user.avatar || "./message-images/avatar.png"} alt="" />
            <span>{user.username}</span>
          </div>
          <button onClick={handleAdd}>Add User</button>
        </div>
      )}
    </div>
  );
};

export default AddUser;
