import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Tabs, Tab } from "@mui/material";
import { Upload, Button, Flex, Spin } from "antd";
import { FaFileUpload } from "react-icons/fa";
import { beforeUpload } from '../../../controllers/common';
import { Link, useLocation, useNavigate } from "react-router-dom";

import { requestService } from "../../../controllers/services/boardingController";
import { commonList } from "../../../controllers/common";


const AddRequestPopUp = ({
  requestAddPopUp,
  requestTogglePopUp,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const { Dragger } = Upload;
  const handleCancel = () => {
    requestTogglePopUp();
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  /**************************** Integration Start Here *****************/
  const [ALLSHOP, setAllShop] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [ALLCAT, setAllCat] = useState([]);
  const [filterSubCat, setFilterSubCat] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [image, setImage] = useState([]);

  const [params, setParams] = useState('');

  /*********************************************************
   *  This function is use to handle image
   *********************************************************/
  const handleImageChnage = (e) => {
    setImage(e.fileList);
  };

  const getCommonList = async () => {
    try {
      const options = {
        list : ["service_category","store","branch"],
        serviceCat : { select : {name : true} },
        serviceSubCat : { select : { category : true, name : true } },
        store : { select : { shop_name : true } },
        branch : { select : { storeData : true, branch_name : true } }
      }
      const res = await commonList(options);
      if(res?.status === true){ 
        setAllShop(res?.result?.store || []);
        setAllBranch(res?.result?.branch || []);
        const filteredArray = res?.result?.serviceCat.filter(item => item.name.toLowerCase() === 'boarding');
        // setFilterBranch(filteredArray);
        setAllCat(filteredArray || []);

      } else{
        setAllShop([]);
        setAllBranch([]);
        setAllCat([]);
      }
    } catch (error) {
      setAllShop([]);
      setAllBranch([]);
      setAllCat([]);
    }
  };

  useEffect(()=>{
    getCommonList();
  },[]);

const handleChnage = (e) =>{
  try {
    const { name, value } = e.target;
    if(name === 'store'){
      const filteredArray = ALLBRANCH.filter(item => item.storeData === value);
      setFilterBranch(filteredArray);
    }

    setErrors((pre)=>({
      ...pre,
      [name] : ""
    }));
    
  } catch (error) {
    
  }
}

const handleTypeClick = (name) => {
  try {
    setParams((pre) => ({
      ...pre,
      [name] : params[name] === "Y"?"N":"Y",
    }));
   
    const type = ['home', 'institue'];
    const size = ['small', 'medium', 'large'];
    if(type.includes(name)){
      setErrors((preError)=>({
        ...preError,
        training_type : ""
      }));
    }

    if(size.includes(name)){
      setErrors((preError)=>({
        ...preError,
        dog_size : ""
      }));
    }
  } catch (error) {
    
  }
}

  
/*********** This function is used for handle form submit ******** */
  const handleSubmit = async (event) => {
    try {
      event.preventDefault(); 
      setIsLoading(true);
      const cat_id = ALLCAT.find((item) => item.name === 'Boarding')?._id;
      const formData = new FormData(event.target);
      formData.append('image',image[0]?.originFileObj);
      formData.append("small", params?.small === 'Y'? "Y":"N");
      formData.append("medium", params?.medium === 'Y'? "Y":"N");
      formData.append("large", params?.large === 'Y'? "Y":"N");
      formData.append("branch", filterBranch[0]._id || "");
      formData.append("category", cat_id || "");
      const isValidate = validateForm(formData);
      if(isValidate){
        const res = await requestService(formData);
        if(res.status === true){
          event.target.reset();
          setIsLoading(false);
          setParams("");
          notification.open({
            message: "Notification Title",
            description: " Request Send Successfully .",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          requestTogglePopUp();
        } else{
          notification.open({
            message: "Oops!!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          setIsLoading(false);
        }
      }else{
        notification.open({
          message: "Validation Error!",
          description: " Please fill the required fields.",
          placement: "topRight",
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };  

/********  */
  const validateForm = (formData) => {
    try {
      if (
        formData.get("store") === "undefined" ||
        formData.get("store") === null ||
        formData.get("store").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          store : "Please select store."
        }));
        return false;
      } else if(
        formData.get("branch") === "undefined" ||
        formData.get("branch") === null ||
        formData.get("branch").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          branch : "Please select branch."
        }));
        return false;
      } else if(
        formData.get("category") === "undefined" ||
        formData.get("category") === null ||
        formData.get("category").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          category : "Please select category."
        }));
        return false;
      } else if(
        formData.get("name") === "undefined" ||
        formData.get("name") === null ||
        formData.get("name").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          name : "Please enter service name."
        }));
        return false;
      } else if(formData.get('small') === 'N' && formData.get('medium') === 'N' && formData.get('large') === 'N'){
        setErrors((preError)=>({
          ...preError,
          dog_size : "Please select dog size."
        }));
        return false;
      } else if(
        formData.get("price") === "undefined" ||
        formData.get("price") === null ||
        formData.get("price").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          price : "Please enter price."
        }));
        return false;
      } else if(
        formData.get("details") === "undefined" ||
        formData.get("details") === null ||
        formData.get("details").trim() === ""
      ){
        setErrors((pre)=>({
          ...pre,
          details : "Please enter details."
        }));
        return false;
      }else{
        return true;
      }
    } catch (error) {
      setErrors((pre)=>({
        ...pre,
        formError : "Server is under maintance."
      }));
      return false
    }
  }

  return (
    <Dialog
      open={requestAddPopUp}
      onClose={requestTogglePopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Request New Service
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form" method="post" onSubmit={handleSubmit} >
                  <div>
                    <div>
                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                        <label
                          htmlFor="store"
                          className="vendor_popup_product_name all_top_and_bottom_for_input_text"
                        >
                          Store :
                        </label>
                        <select name="store" id="store" className="form-control all_input_tag_tagenter_product_" onChange={handleChnage}>
                            <option value="">Select Store</option>
                            {ALLSHOP?.length > 0 ? (ALLSHOP.map((items, index)=>(<>
                              <option key={index} value={`${items?._id}`}>{items?.shop_name}</option>
                            </>))) : (<option value="">No Data Found</option>)}
                        </select>
                        {errors?.store?(<p style={{color:'red'}}>{errors.store}</p>):""}
                      </div>

                      {/* <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                        <label
                          htmlFor="store"
                          className="vendor_popup_product_name all_top_and_bottom_for_input_text"
                        >
                          Branch :
                        </label>
                        <select name="branch" id="branch" className="form-control all_input_tag_tagenter_product_" onChange={handleChnage}>
                            <option value="">Select products</option>
                            {filterBranch?.length > 0 ? (filterBranch.map((items, index)=>(<>
                              <option key={index} value={`${items?._id}`}>{items?.branch_name}</option>
                            </>))) : (<option value="">No Data Found</option>)}
                        </select>
                        {errors?.branch?(<p style={{color:'red'}}>{errors.branch}</p>):""}
                      </div> */}
                      
                      {/* <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                        <label
                          htmlFor="category"
                          className="vendor_popup_product_name all_top_and_bottom_for_input_text"
                        >
                          Category :
                        </label>
                        <select name="category" id="category" className="form-control all_input_tag_tagenter_product_" onChange={handleChnage}>
                            <option value="">Select products</option>
                            {ALLCAT?.length > 0 ? (ALLCAT.map((items, index)=>(<>
                              <option 
                                key={index} 
                                value={`${items?._id}`}
                                selected={items.name.toLowerCase() === 'boarding'?true:false}
                              >
                                  {items?.name}
                              </option>
                            </>))) : (<option value="">No Data Found</option>)}
                        </select>
                        {errors?.category?(<p style={{color:'red'}}>{errors.category}</p>):""}
                      </div> */}

                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                        <label
                          htmlFor="name"
                          className="vendor_popup_product_name all_top_and_bottom_for_input_text"
                        >
                          Service Name :
                        </label>
                        <input type="text" name="name" id="name" placeholder="Enter service name" className="form-control all_input_tag_tagenter_product_" onChange={handleChnage}/>
                        {errors?.name?(<p style={{color:'red'}}>{errors.name}</p>):""}
                      </div>

                      <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label htmlFor="phone" className="all_top_and_bottom_for_input_text vendor_popup_product_name">
                          <span style={{ color: "red" }}>*</span>Dog Size :
                        </label>
                        <Flex vertical gap="middle" className="">
                          <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="small" id="small" size="large" >
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" onClick={()=>handleTypeClick('small')}>
                                <div className="vendor_dashbaord_gender_main_section">
                                    <label htmlFor="male">Small</label>
                                    <input
                                      type="radio"
                                      id="small"
                                      name="small"
                                      value="Y"
                                      className="form-check-input"
                                      disabled={true}
                                      checked={params.small === 'Y'?true:false}
                                    />
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" onClick={()=>handleTypeClick('medium')}>
                                <div className="vendor_dashbaord_gender_main_section">
                                    <label htmlFor="medium">Medium</label>
                                    <input
                                      type="radio"
                                      id="medium"
                                      name="medium"
                                      value="Y"
                                      className="form-check-input"
                                      disabled={true}
                                      checked={params.medium === 'Y'?true:false}
                                    />
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" onClick={()=>handleTypeClick('large')}>
                                <div className="vendor_dashbaord_gender_main_section">
                                    <label htmlFor="large">Large</label>
                                    <input
                                      type="radio"
                                      id="large"
                                      name="large"
                                      value="Y"
                                      className="form-check-input"
                                      disabled={true}
                                      checked={params.large === 'Y'?true:false}
                                    />
                                </div>
                              </div>
                              {errors?.dog_size?<p style={{color:"red"}}>{errors.dog_size}</p>:""}
                            </div>
                          </div>
                        </Flex>
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                        <label
                          htmlFor="price"
                          className="vendor_popup_product_name all_top_and_bottom_for_input_text"
                        >
                          Price :
                        </label>
                        <input type="number" name="price" id="price" placeholder="Enter service price" className="form-control all_input_tag_tagenter_product_" onChange={handleChnage} />
                        {errors?.price?(<p style={{color:'red'}}>{errors.price}</p>):""}
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                        <label
                          htmlFor="details"
                          className="vendor_popup_product_name all_top_and_bottom_for_input_text"
                        >
                          Details :
                        </label>
                        <textarea name="details" id="details" row="4" col="4" className="form-control all_input_tag_tagenter_product_" placeholder="Enter service details" onChange={handleChnage} ></textarea>
                        {errors?.details?(<p style={{color:'red'}}>{errors.details}</p>):""}
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                        <label
                          htmlFor="details"
                          className="vendor_popup_product_name all_top_and_bottom_for_input_text"
                        >
                          Image :
                        </label>
                        <Dragger
                          className="about_us_dragger_file"
                          maxCount="1"
                          listType="picture"
                          onChange={handleImageChnage}
                          beforeUpload={beforeUpload}
                        >
                          <button
                            type="button"
                            className="btn btn-danger"
                          >
                            Upload file
                          </button>
                        </Dragger>
                        <p style={{color:"blue"}} className="all_top_and_bottom_for_input_text">*Note: Please choose an image of JPEG/JPG/PNG format file.</p>
                        {errors?.image?(<p style={{color:'red'}}>{errors.image}</p>):""}
                      </div>
                      


                    </div>

                    <div className="inventory_open_popup_action_btn_upload_main">
                    {errors?.formError?(<p style={{colors:'red'}}>{errors.formError}</p>):""}
                      <button
                        type="button"
                        className="btn btn-light categories_cancel_button"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-danger "
                        // onClick={handleDelete}
                      >
                        Raise request
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
        </div>
      </div>
      <Flex align="center" gap="middle">
        <Spin 
          size="large" 
          spinning={isLoading}
          fullscreen={true}
        />
      </Flex>
    </Dialog>
  );
};

export default AddRequestPopUp;
