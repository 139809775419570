import { API_VENDOR_URL } from "../config/constants";
import { getRequest, postRequest } from "./API"; 
import { Upload } from "antd";
import { notification } from "antd";

export const getData = async (options) =>{
    try {
        const res = await postRequest(options);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}

export const addData = async (options) =>{
    try {
        const res = await postRequest(options);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}

/*********************************************************
* Function Name : List
* Description   : Get list of all common listing
* By            : Afsar Ali
* Date          : 27-03-2024 
*********************************************************/
export const commonList = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}common/list`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function



export const formatedDate = (inputDate) => {
    const months = [
        "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];

    const date = new Date(inputDate);
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
}

export const statusMessage = (status) => {
    try{
        if(status === 'A'){
            return `<p class="status-active" >Active</p>`;
        } else if(status === 'B'){
            return `<p class="status-danger" >Blocked</p>`;
        } else if(status === 'I'){
            return `<p class="status-inactive" >Inactive</p>`;
        } else if(status === 'D'){
            return `<p class="status-danger" >Deleted</p>`;
        }
    } catch(error) {
        console.log(error) 
        return '';
    }
}

export const RequestStatusMessage = (status) => {
    try{
        if(status === 'P'){
            return `<p class="text-warning" >Pending</p>`;
        } else if(status === 'C'){
            return `<p class="status-success" >Completed</p>`;
        } else if(status === 'R'){
            return `<p class="text-danger" >Rejected</p>`;
        } else{
            return `<p class="" >${status}</p>`;
        }
    } catch(error) {
        console.log(error) 
        return '';
    }
}

export const bookingStatus = (status) => {
    try{
        if(status === 'Pending'){
            return `<p class="text-warning" >${status}</p>`;
        } else if(status === 'Fail' || status === 'Accept'){
            return `<p class="text-info" >${status}</p>`;
        } else if(status === 'Fail' || status === 'Canceled'){
            return `<p class="text-danger" >${status}</p>`;
        } else if(status === 'Complete' || status === 'Completed'){
            return `<p class="text-success" >${status}</p>`;
        } else {
            return `<p class="text-danger" >${status}</p>`;
        }
    } catch(error) {
        // console.log(error) 
        return '';
    }
}

export const getPage = (Nos) => {
    const page = parseInt(Nos/10) + 1;
    // console.log(page);
    return page
}

export const authPermission = (permission, path) => {
    try {
      const userData = JSON.parse(sessionStorage.getItem('ADMIN-INFO'));
      if (userData.admin_type === "Super Admin") {
        return true;
      } else {
        const permissionModel = JSON.parse(localStorage.getItem('SIDE-MENU'));
        const foundModule = permissionModel.find((module) => {
          const checkModel = path.includes(`${module.moduleName}`);
          if (checkModel) {
            const foundItem = module.firstData.find((item) => path === `/${module.moduleName}-${item.moduleName}`);
            return foundItem && foundItem[permission] === 'Y';
          }
          return false;
        });
        return !!foundModule;
      }
    } catch (error) {
    //   console.error('Error checking permissions:', error);
      return false;
    }
};


/*********************************************************
* Function Name : ucfirst
* Description   : Get list of ucfirst
* By            : Noor Alam
* Date          : 30-04-2024 
*********************************************************/
export const ucfirst = (str) => {
    try{
        return str.charAt(0).toUpperCase() + str.slice(1);
    }catch (error){
        return str;
    }
}



/*********************************************************
 *  This function is use to validate image format file should be jpg/jpeg/png
 *********************************************************/
export const beforeUpload = async (file) => {
    try {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
  
      if (!isJpgOrPng) {
        notification.error({
          message: "Invalid file type",
          description: "You can only upload JPG/JPEG/PNG file!",
          placement: "topRight",
        });
        throw new Error("Invalid file type");
      }
  
      return isJpgOrPng;
    } catch (error) {
      console.error("Error in beforeUpload:", error);
      return Upload.LIST_IGNORE;
    }
  };