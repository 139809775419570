import { create } from "zustand";
import { useUserStore } from "./userStore";

export const useChatStore = create((set) => ({
  chatId: null,
  user: null,

  changeChat: (chatId, user) => {
    const currentUser = useUserStore.getState().currentUser;

    // Ensure chatId and user.id are strings
    if (typeof chatId !== "string") {
      console.error("chatId must be a string", chatId);
      return;
    }

    if (typeof user.id !== "string") {
      console.error("user.id must be a string", user.id);
      return;
    }

    console.log("changeChat called with", chatId, user);

    return set({
      chatId,
      user,
    });
  },

  // Add resetChat function to reset chat state
  resetChat: () => {
    return set({
      chatId: null,
      user: null,
    });
  },
}));
