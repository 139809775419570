import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import { notification } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import { deleteProduct } from "../../../controllers/Products/product";
import { useNavigate } from "react-router-dom";

const ProductListDeletePopUp = ({ popUpOpen, togglePopUp ,EDITDATA}) => {
  const navigate=useNavigate()
  const handleCancel = () => {
    togglePopUp();
  };

  const handleDelete = async () => {
    const param ={
      url :`/poductsmaster/delete`,
      postData : { id : EDITDATA?._id } 
    }
    const res = await deleteProduct(param);
    if(res.status === true){
      notification.open({
        message: "Deleted!",
        description: " Data Deleted Successfully .",
        placement: "topRight",
        duration : 2,
        icon: <DeleteOutlined style={{ color: "red" }} />,
      });
    }else{
      notification.open({
        message: "Opps!!",
        description: " Please try again.",
        placement: "topRight",
        duration : 2,
        icon: <DeleteOutlined style={{ color: "red" }} />,
      });
    }
    // setDeteteId("");
    handleCancel();
    navigate('/product/list')
  };

  return (
    <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirm
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUp}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Do you want to remove this item?</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} style={{ color: "red" }}>
            Delete
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ProductListDeletePopUp;
