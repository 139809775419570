import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu } from "antd";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { FaPlusSquare, FaEdit, FaTimes, FaCheck } from "react-icons/fa";
import { Select, notification, Switch, Drawer, Button, Space  } from "antd";
import "../../Promotions/Promotions.css";
import PromotionsAddEditPopUp from "./addEditAdoption";
import PromotionsDeletePopUp from "../../Promotions/PromtionsDeletePopUp";
import Top_Navbar from "../../DashBaord/Top_navbar";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { ASSETS_BASE_URL } from "../../../config/constants";
import SkeltonList from "../../../components/SkeltonEffect/list";
import {
  adoptionsList,
  adoptionStatus,
} from "../../../controllers/services/dogAdoption";
import { getPage, statusMessage } from "../../../controllers/common";

function Promotions(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

Promotions.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  // delete PopUp
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [promotionsAddEditPopUpmodal, setAddEditPromoCode] = useState(false);
  const promotionaToggleAddEditPopUp = () => {
    setAddEditPromoCode(!promotionsAddEditPopUpmodal);
  };

  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  }

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /************************************************************************************* */
  const [addEditData, setAddEditData] = useState("");
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: new Date(),
    filter_by: "",
    search: "",
  }); //End
  const [total_active, setTotalActive] = useState(0);
  const [total_inactive, setTotalinactive] = useState(0);

  const [showRequest, setShowRequest] = useState("");

  const handleEdit = async (item = {}) => {
    navigate("/vendors/addeditdata", { state: item });
  };
  /*********************************************************
   *  This function is use to fetch vendor list
   *********************************************************/
  const getList = async () => {
    setListData([]);
    const options = {
      type: "",
      conditon: {
        ...(showRequest ? { status: showRequest } : null)
      },
      ...(filter ? { filter: filter } : null),
      select: {},
      sort: { _id: -1 },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10,
    };
    const listData = await adoptionsList(options);
    if (listData.status === true) {
      if (listData.result.length > 0) {
        setListData(listData?.result);
        setTotalPages(getPage(listData?.count?.total || 0));
        setTotalinactive(listData?.count?.inactive || 0);
        setTotalActive(listData?.count?.active || 0);
      } else {
        setListData([{ title: "No Data Found" }]);
        setTotalPages(getPage(0));
        setTotalinactive(0);
        setTotalActive(0);
      }
    } else {
      setListData([{ title: "No Data Found" }]);
    }
  }; //End

  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  /*********************************************************
   *  This function is use to handle chnage status
   *********************************************************/
  const handleStatusChnage = async (item) => {
    try {
      let status = item.status === "A" ? "I" : "A";
      setListData([]);
      const options = {
        id: item?._id,
        status: status,
      };
      const res = await adoptionStatus(options);
      if (res.status === true) {
        notification.open({
          message: "Data updated.",
          description: `Data update successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Opps!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
    getList();
  };

    /*********************************************************
*  This function is ued for handle filter input chnage
*********************************************************/
const handleFilterReset = () => {
  try {
    setFilter('');
    inputRef1.current.value = '';
    inputRef2.current.value = '';
    inputRef3.current.value = '';
    inputRef4.current.value = '';
  } catch (error) {

  }
}

const handleFilterApply = (e) => {
  try {
    e.preventDefault();
    const form = new FormData(e.target);
    setFilter((pre) => ({
      ...pre,
      'filter_by': form.get('filter_by') || '',
      'search': form.get('search') || '',
      'to': form.get('to_date') || '',
      'from': form.get('from_date') || '',

    }))
    handleFiterDrawer();
  } catch (error) {

  }
}
  /*********************************************************
   *  This function is load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    })
    document.title = "Frisbee Vendor Dashboard || Adoption Service List";
  }, [currentPage, showRequest, promotionsAddEditPopUpmodal, filter]);

  const dropdownMenu = (item) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setAddEditData(item);
          promotionaToggleAddEditPopUp();
        }}
      >
        <FaEdit />
        <span className="show_span_edit">Edit</span>
      </Menu.Item>
      {item?.status === "A" ? (
        <Menu.Item key="2" onClick={() => handleStatusChnage("I", item._id)}>
          <FaTimes /> <span className="show_span">Inactive</span>
        </Menu.Item>
      ) : (
        <Menu.Item key="2" onClick={() => handleStatusChnage("I", item._id)}>
          <FaCheck /> <span className="show_span">Active</span>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <Top_Navbar title={`Adoption Service`}></Top_Navbar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={() => setShowRequest("")}
                    />
                    <Tab
                      label="Active"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                      onClick={() => setShowRequest("A")}
                    />
                    <Tab
                      label="Inactive"
                      className="tabs_main_contnet"
                      {...a11yProps(2)}
                      onClick={() => setShowRequest("I")}
                    />
                  </Tabs>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <Promotions>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="promotions_table_heading">
                      <div>
                        <h6 className="m-0 promotions_heading">
                          Dog Adoption list
                        </h6>
                      </div>
                      <div className="promotions_filters_div">
                        <div>
                          <h6 className="m-0 promotions_heading_filter" onClick={handleFiterDrawer}>
                            <div className="inventory_filter_div">
                              <img
                                src={ASSETS_BASE_URL + "./images/Filter.svg"}
                                alt=""
                                className="user_iconses"
                              />

                              <Link
                                to={false}
                                className="filter_btn_invontry_rating_review"
                              >
                                Filters
                              </Link>
                            </div>
                          </h6>
                        </div>
                        <div>
                          <button
                            type="button"
                            class="btn btn-dark"
                            onClick={promotionaToggleAddEditPopUp}
                          >
                            <FaPlusSquare className="Promotions_faPlus_Squares" />
                            Add New
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Image</th>
                              <th>Pet Name</th>
                              <th>Description</th>
                              <th>Breed</th>
                              <th>Gender</th>
                              <th>createdAt</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr>
                                    <td>
                                      {" "}
                                      {items?.image ? (
                                        <>
                                          <img
                                            src={ASSETS_BASE_URL + items?.image}
                                            alt=""
                                            width="100px"
                                            className="img_adoptionsss"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={
                                              "../images/IntentoryTableLogo.png"
                                            }
                                            alt=""
                                          />
                                        </>
                                      )}
                                    </td>
                                    <td>{items?.pet_name} </td>
                                    <td>{items?.description} </td>
                                    <td>{items?.breed} </td>
                                    <td>{items?.gender} </td>
                                    <td>
                                      {moment(`${items.createdAt}`).format(
                                        "DD/MM/YYYY, h:m A"
                                      )}
                                    </td>
                                    <td>
                                      <Switch
                                        className="categories_switch_"
                                        checked={
                                          items?.status === "A" ? true : false
                                        }
                                        onClick={() =>
                                          handleStatusChnage(items)
                                        }
                                      />
                                    </td>
                                    {/* <td>Percentage ({items.discounts}%)</td>
                              <td>
                                {moment(`${items.valid_date}`).format("DD/MM/YYYY")} {" "}
                                {moment(`2024-01-01 ${items.valid_time}:00`).format("h:m A")}
                              </td>
                              <td>
                                {moment(`${items.expire_date}`).format("DD/MM/YYYY")} {" "}
                                {moment(`2024-01-01 ${items.expire_time}:00`).format("h:m A")}
                              </td>
                              <td>{items.qty}</td>
                              <td dangerouslySetInnerHTML={{__html: statusMessage(items.status)}}></td> */}

                                    <td>
                                      <Dropdown
                                        overlay={() => dropdownMenu(items)}
                                        placement="bottomLeft"
                                        arrow
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : (
                              <SkeltonList row={5} col={7} />
                            )}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Promotions>
          </Box>
        </div>
        <PromotionsDeletePopUp
          popUpOpen={popUpOpen}
          togglePopUp={togglePopUp}
        />
        <PromotionsAddEditPopUp
          promotionsAddEditPopUpmodal={promotionsAddEditPopUpmodal}
          promotionaToggleAddEditPopUp={promotionaToggleAddEditPopUp}
          addEditData={addEditData}
          setAddEditData={setAddEditData}
        />
      </div>
        {/* Filter */}
        <Drawer
        title="Inventory Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}>
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id='filter_form' onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  Search Field :
                </label>
                <select name="filter_by" className="form-control store_input_field_field" id="filter_by" ref={inputRef1}>
                  <option value=''>Select search field</option>
                  <option value='store_name' selected={filter?.filter_by === 'store_name' ? true : false}>Store Name</option>
                  <option value='store_phone' selected={filter?.filter_by === 'store_phone' ? true : false}>Store Phone</option>
                  <option value='store_email' selected={filter?.filter_by === 'store_email' ? true : false}>Store Email</option>
                  <option value='pet_name' selected={filter?.filter_by === 'pet_name' ? true : false}>Pet Name</option>
                  <option value='breed' selected={filter?.filter_by === 'breed' ? true : false}>Breed</option>
                  <option value='gender' selected={filter?.filter_by === 'gender' ? true : false} >Gender</option>
                  <option value='status' selected={filter?.filter_by === 'status' ? true : false}>Status</option>
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field_field"
                  id="search"
                  ref={inputRef2}
                // onChange={handleFilterChnage} 
                // value={filter?.search}             
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  From :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="from_date"
                  className="form-control store_input_field_field"
                  id="from_date"
                  ref={inputRef3}
                // onChange={handleFilterChnage}          
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  To :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="to_date"
                  className="form-control store_input_field_field"
                  id="to_date"
                  ref={inputRef4}
                // onChange={handleFilterChnage}             
                />
              </div>
              <br />
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-primary "
                  style={{ backgroundColor: "black", color: "white",border:"none" }}
                >Apply</button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
}
