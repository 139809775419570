import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Switch } from "antd";
import { ASSETS_BASE_URL } from "../../config/constants";
import {updateVarient} from '../../controllers/Products/inventory'
import LoadingEffect from '../../components/Loading/LoadingEffect'
const InventoryEditPagePopUp = ({ inventoryPopUp, inventoryTogglePopUp, addEditData, setAddEditData }) => {
  const [errors, setErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const handleCancel = () => {
    setAddEditData("");
    setErrors("")
    inventoryTogglePopUp();
    setIsLoading(false);
  };
  const showSuccessNotification = () => {
    notification.open({
      message: "Success",
      description: `Inventory updated successfully`,
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="green" />,
    });
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  const handleChnage = (e) => {
    try {
      const {name, value} = e.target;
      setAddEditData((pre)=>({
        ...pre,
        [name] : value
      }))
    } catch (error) {
      
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    try {
      setIsLoading(true);
      const formData = new FormData(e.target);
      formData.append('id',addEditData?._id)
      if(formData.get('id') === 'undefined' || formData.get('id') === null || formData.get('id').trim() === ''){
        setErrors((pre)=>({
          ...pre,
          id : 'Inventory not found.'
        }));
      } else if(formData.get('rate') === 'undefined' || formData.get('rate') === null || formData.get('rate').trim() === ''){
        setErrors((pre)=>({
          ...pre,
          rate : 'Rate is required.'
        }));
      } else if(formData.get('discount') === 'undefined' || formData.get('discount') === null || formData.get('discount').trim() === ''){
        setErrors((pre)=>({
          ...pre,
          discount : 'Discount is required.'
        }));
      } else { 
        const options = {
          id : formData.get('id'),
          rate : formData.get('rate'),
          discount : formData.get('discount')  
        }
        const res= await updateVarient(options);
        showSuccessNotification();
        handleCancel();
        setIsLoading(false);
      }
    } catch (error) {
      // console.log('error',error)
      setIsLoading(false);      
    }
  }
  
  
  return (
    <Dialog
      open={inventoryPopUp}
      onClose={inventoryTogglePopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Edit Product
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <div className="row">
                  <div className="form-group col-lg-4 col-md-4 col-sm-6">
                    <img
                      src={`${ASSETS_BASE_URL}${addEditData?.productData?.image}`}
                      alt="product-image"
                      width="100px"
                    />
                  </div>
                  <div className="form-group col-lg-8 col-md-8 col-sm-12">
                    <label
                      for="product Image"
                      className="all_lable_for_vendor_dashbaord"
                    >
                      {" "}
                      {addEditData?.productData?.name}
                    </label>
                    <p>{`${addEditData?.productData?.category?.name}/${addEditData?.productData?.subcategory?.name}`}</p>
                    <p> Unit : {addEditData?.unit}</p>
                    <p> Quantity : {addEditData?.qty?addEditData?.qty:0} Nos.</p>
                  </div>
                </div>
                <hr/>
                <form className="categories_open_popup_form" method="post" onSubmit={handleSubmit}>
                  <div class="form-group">
                    <div class="form-group">
                      <label
                        for="Rate"
                        className="all_lable_for_vendor_dashbaord"
                      >
                        {" "}
                        Amount{" "}
                      </label>
                      <input
                        type="number"
                        name="rate"
                        is="rate"
                        onChange={handleChnage}
                        value={addEditData?.rate}
                        class="form-control store_input_field_field"
                        placeholder="Enter product rate."
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="form-group">
                      <label
                        for="formGroupExampleInput"
                        className="all_lable_for_vendor_dashbaord"
                      >
                        {" "}
                        Discount (%){" "}
                      </label>
                      <input
                        type="number"
                        class="form-control store_input_field_field"
                        name="discount"
                        id="discount"
                        onChange={handleChnage}
                        value={addEditData?.discount} 
                        placeholder="Enter producr discount (%)."
                      />
                    </div>
                  </div>
                  
                  <div className="inventory_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="btn btn-danger "
                      // onClick={handleDelete}
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
          <LoadingEffect isLoading = {isLoading} />
        </div>
      </div>
    </Dialog>
  );
};

export default InventoryEditPagePopUp;
