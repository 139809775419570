import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu } from "antd";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { FaEdit} from "react-icons/fa";
import { notification } from "antd";
import "./Insights.css";
import NotificationViewPopUp from "./AddEditPopUp";
import NotificationDeletePopUp from "./DeletePopUp";
import Top_Navbar from '../DashBaord/Top_navbar'
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { ASSETS_BASE_URL } from "../../config/constants";
import SkeltonList from "../../components/SkeltonEffect/list";
import { NotificationsList, deleteNotifications } from "../../controllers/notifications/notificationsControllers";
import { getPage} from "../../controllers/common";


function Notification(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

Notification.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  // delete PopUp
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  
  const [NotificationsPopUpmodal, setAddEditPromoCode] = useState(false);
  const notificationToggleViewPopUp = () => {
    setAddEditPromoCode(!NotificationsPopUpmodal);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /************************************************************************************* */
  const [addEditData, setAddEditData] = useState("");
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const [deleteId, setDeleteId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from : '',
    to : new Date(),
    filter_by : "",
    search : ""
  })//End
  const [total_active, setTotalActive] = useState(0);
  const [total_inactive, setTotalinactive] = useState(0);
  const [showRequest, setShowRequest] = useState("");

  /*********************************************************
  *  This function is use to fetch vendor list
  *********************************************************/
  const getList = async ()=>{
    setListData([]);
    const options = {
      type : "",
      condition : { 
        ...(filter?.from ? {
          createdAt: {
            '$gte': moment(filter?.from).format('YYYY-MM-DD'),
            '$lte': moment().format('YYYY-MM-DD'),
          },
        } : null),
        ...(filter?.search?{[filter?.filter_by]:{ $regex: filter.search, $options: 'i' }}:null),
        ...(showRequest?{status : showRequest}:null)
      },
      select    : { },
      sort      : {"_id" : -1},
      skip      : SKIP?SKIP:0,
      limit     : LIMIT?LIMIT:10
    }
    const listData = await NotificationsList(options);
    if(listData.status === true){
      if(listData.result.length > 0){
        setListData(listData?.result);
        setTotalPages(getPage(listData?.count|| 0)); 
      } else{
        setListData([{title : 'No Data Found'}]);
        setTotalPages(getPage(0));   
        setTotalinactive(0);
        setTotalActive(0);
      }
    }else{
      setListData([{title : 'No Data Found'}]);
    }
  };//End

  /*********************************************************
  *  This function is for handle page change
  *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage -1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };
  /*********************************************************
  *  This function is use to handle chnage status
  *********************************************************/
  const handleNotification = async (id) => {
    try{
      setListData([]);
      const options ={
          url: `notifications/delete-notifications`,
          postData: {
            user_id : id,
        }
      }
      const res = await deleteNotifications(options);
      if(res.status === true){
        notification.open({
          message: "Data updated.",
          description:  `Notifications Delete successfully.`,
          placement: "topRight", 
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
          duration:2
        });
      } else{
        notification.open({
          message: "Opps!",
          description:  `${res?.message}`,
          placement: "topRight", 
          icon: <InfoCircleOutlined style={{ color: 'red' }} />,
          duration:2
        });
      }
    } catch (error){
      notification.open({
        message: "Opps!",
        description:  `Operation not perform yet! please try in some time.`,
        placement: "topRight", 
        icon: <InfoCircleOutlined style={{ color: 'red' }} />,
        duration:2
      });
    }
    getList();
  }

  /*********************************************************
  *  This function is load when page load and with dependency update
  *********************************************************/
  useEffect(()=>{
    getList();
    document.title = 'Frisbee Vendor Admin || Notifications List'
  },[currentPage,showRequest, NotificationsPopUpmodal, deleteId]);

  const dropdownMenu = (item)=> (
    <Menu>
      <Menu.Item key="1" onClick={()=>{
        setAddEditData(item);
        notificationToggleViewPopUp()
        }}>
        <FaEdit />
        <span className="show_span_edit">View</span>
      </Menu.Item>
      
        <Menu.Item key="2" onClick={()=>{
          setDeleteId(item._id);
          togglePopUp();
        }} >
          <MdDelete /> <span className="show_span">Delete</span>
        </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <Top_Navbar title={`Notifications`}></Top_Navbar>
        
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={()=>setShowRequest('')}
                    />
                    <Tab
                      label="Unread"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                      onClick={()=>setShowRequest('U')}
                    />
                    <Tab
                      label="Read"
                      className="tabs_main_contnet"
                      {...a11yProps(2)}
                      onClick={()=>setShowRequest('R')}
                    />
                  </Tabs>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <Notification>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="promotions_table_heading">
                      <div>
                        <h6 className="m-0 promotions_heading">
                          Notifications list
                        </h6>
                      </div>
                      <div className="promotions_filters_div">
                        {/* <div>
                          <h6 className="m-0 promotions_heading_filter">
                            <div className="fa_filter_rating_review">
                              <img
                                src={ASSETS_BASE_URL + "./images/Filter.svg"}
                                alt=""
                                className="user_iconses"
                              />

                              <Link to={false} className="filter_btn_invontry_rating_review" >
                                Filters
                              </Link>
                            </div>
                          </h6>
                        </div> */}
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Image</th>
                              <th>Title</th>
                              <th>Description</th>
                              <th>Date & Time</th>
                              {/* <th>Status</th> */}
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr>
                                    <td>
                                      <img
                                        src={
                                          items.image
                                            ? `${ASSETS_BASE_URL}${items.image}`
                                            : "https://frisbee-llc.s3.me-central-1.amazonaws.com/images/favicon.png"
                                        }
                                        width="70px"
                                        alt="Item"
                                      />
                                    </td>
                                    <td>{items.title}</td>
                                    <td>{items.message}</td>
                                    <td>
                                      {moment(`${items.createdAt}`).format(
                                        "DD/MM/YYYY h:m A"
                                      )}{" "}
                                    </td>
                                    {/* <td dangerouslySetInnerHTML={{__html: statusMessage(items.status)}}></td> */}

                                    <td>
                                      <Dropdown
                                        overlay={() => dropdownMenu(items)}
                                        placement="bottomLeft"
                                        arrow
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : (
                              <SkeltonList row={5} col={7} />
                            )}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Notification>
          </Box>
        </div>
        <NotificationDeletePopUp
          popUpOpen={popUpOpen}
          togglePopUp={togglePopUp}
          deleteId ={deleteId}
          setDeleteId={setDeleteId}
          handleNotification={handleNotification}
        />
        <NotificationViewPopUp
          NotificationsPopUpmodal={NotificationsPopUpmodal}
          notificationToggleViewPopUp={notificationToggleViewPopUp}
          viewData={addEditData}
          setAddEditData={setAddEditData}
        />
      </div>
    </>
  );
}
