module.exports = {
    API_KEY : "d42a0d190464a2be90977c3996382811",

    API_ADMIN_URL : "https://api.tryfrisbee.com/admin/",
    API_VENDOR_URL : "https://api.tryfrisbee.com/vendor/",
    API_BASE_URL : "https://api.tryfrisbee.com/",
    ASSETS_BASE_URL : "https://frisbee-llc.s3.me-central-1.amazonaws.com/",
    BASE_URL : 'http://algodev.in:62000/'

    // API_ADMIN_URL : "https://algodev.in:60000/admin/",
    // API_VENDOR_URL : "https://algodev.in:60000/vendor/",
    // API_BASE_URL : "https://algodev.in:60000/",
    // ASSETS_BASE_URL : "https://frisbee-llc.s3.me-central-1.amazonaws.com/",
    // BASE_URL : 'http://algodev.in:62000/'

    // API_ADMIN_URL : "http://localhost:3300/admin/",
    // API_VENDOR_URL : "http://localhost:3300/vendor/",
    // API_BASE_URL : "http://localhost:3300/",
    // ASSETS_BASE_URL : "https://frisbee-llc.s3.me-central-1.amazonaws.com/",
    // BASE_URL : 'http://localhost:3000/'
}