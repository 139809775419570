import { API_VENDOR_URL } from "../../config/constants";
import { postRequest, fetchIpAddress } from "../API";

/*********************************************************
* Function Name : createPet for dog request_adoption services
* Description   : This function is used for createPet dog adoption services
* By            : Noor Alam
* Date          : 3May-2024 
*********************************************************/
export const createPet = async (options) => {
    try {
        const params = {
            url: `${API_VENDOR_URL}services/adoption/createPet`,
            postData: options
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage };
        }
    } catch (error) {
        console.log(error);
        return { status: false, message: "Under maintanance, Please try after some time." };
    }
}


/*********************************************************
* Function Name : dogAdoptionList
* Description   : This function is used for createStoreList for dog Friendly from request_adoption table
* By            : Noor Alam
* Date          : 29 April-2024 
*********************************************************/
export const list = async (options) => {
    try {
        const params = {
            url: `${API_VENDOR_URL}services/adoption/list`,
            postData: options
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response.result };
        } else {
            return { status: false, result: res?.reponse?.data?.statusMessage };
        }
    } catch (error) {
        console.log(error);
        return { status: false, message: "under maintaince, please try after some time." }
    }
}


/*********************************************************
* Function Name : changeStatus
* Description   : This function is used for change status for dog Friendly from request_adoption table
* By            :  Noor Alam
* Date          : 3May-2024 
*********************************************************/
export const changeStatus = async (options) => {
    try {
        const params = {
            url: `${API_VENDOR_URL}services/adoption/changeStatus`,
            postData: {
                ...options,
                ipAddress: await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.changeStatus };
        }
    } catch (error) {
        console.log(error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
};//End of Function



/*********************************************************
* Function Name : dogAdoptionList from adoption table
* Description   : This function is used for  dog adoption list from adoption table
* By            : Noor Alam
* Date          : 7May-2024 
*********************************************************/
export const adoptionsList = async(options) => {
    try{
     const params = {
        url: `${API_VENDOR_URL}services/adoptions/lists`,
        postData: options
     }
     const res = await postRequest(params);
     if(res.status === true || res.status === 200){
        return { status: true, result: res?.data?.response.result};
     }else{
        return {status: false, result: res?.response?.data?.statusMessage };
     }
    }catch(error){
        console.log("Error", error);
        return {status: false, message: "Under Maintenance, Please try after some time."}
    }
}


/*********************************************************
* Function Name : dogAdoption status from adoption table
* Description   : This function is used for  dog adoption status from adoption table
* By            : Noor Alam
* Date          : 7 May-2024 
*********************************************************/
export const adoptionStatus = async (options) => {
    try{
       const params = {
        url: `${API_VENDOR_URL}services/adoption/changeStatus`,
        postData: {
            ...options,
            ipAddress: await fetchIpAddress()
        }
       }

       const res = await postRequest(params);
       if (res.status === true || res.status === 200) {
        return { status: true, result: res?.data?.response?.result };
    } else {
        return { status: false, message: res?.response?.data?.changeStatus };
    }
  } catch (error) {
    console.log("Error", error);
    return {
      status: false,
      message: "Under Maintenance, Please try after some time.",
    };
  }
};
/*********************************************************
 * Function Name : List
 * Description   : Get list of all promocode list
 * By            : Afsar Ali
 * Date          : 02-04-2024
 *********************************************************/
export const breedList = async (options) => {
  try {
    const params = {
      url: `${API_VENDOR_URL}services/breeds/list`,
      postData: options,
    };
    console.log(params.url, " url co");
    const res = await postRequest(params);

    // console.log("API Response:", res); // Log the response for debugging

    // Check for successful status
    if (res.status === true || res.status === 200) {
      const result = res?.data?.response?.result;
      // const count = res?.data?.response?.count;

      if (Array.isArray(result)) {
        return {
          status: true,
          result,
        };
      } else {
        console.error("Invalid result format");
        return { status: false, message: "Invalid data format" };
      }
    } else {
      console.error("API Error:", res?.response?.data?.statusMessage);
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    console.error("Error in breedList:", error);
    return {
      status: false,
      message: "Under Maintenance, Please try again later.",
    };
  }
}; // End of Function
