import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useEffect, useState, useRef } from "react";
import { Dropdown, Menu, Switch, notification } from "antd";
import { FaEdit, FaPlusSquare } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import TobNavBar from "../DashBaord/Top_navbar";
import { useNavigate } from "react-router-dom";
// import { chnageStatus, list } from "../../controllers/Products/product";
import { list } from "../../controllers/stores/storeController";
import { ASSETS_BASE_URL } from "../../config/constants";
import moment from "moment";
import Skeltonlist from "../../components/SkeltonEffect/list";
import "./Store.css";


function ProductList(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

ProductList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [varientToggleProductPopUp, setvarientToggleProductPopUp] =
    useState(false);
  const [VarientData, setVarientData] = useState("");
  const opentoggleVarient = (item) => {
    setvarientToggleProductPopUp(!varientToggleProductPopUp);
    setVarientData(item);
  };
  const targetRef = useRef(null);
  const navigate = useNavigate();
  //BulkUploadModel
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const openBulkUploadPopUp = () => {
    setShowBulkUpload(!showBulkUpload);
  };
  //End
  // delete PopUp
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [EDITDATA, setEditData] = useState([]);
  const handleAddEditData = () => {
    navigate("/store/details", { state: EDITDATA });
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1">
        <FaEdit />
        <span className="show_span_edit" onClick={handleAddEditData}>
          Manage Appointment 
        </span>
      </Menu.Item>
      {/* <Menu.Item key="2" onClick={togglePopUp}>
        <MdDelete /> <span className="show_span">Delete</span>
      </Menu.Item> */}
    </Menu>
  );

  /*********************************************************
   *  This function is use to handle notification
   *********************************************************/
  const handleNotification = (status, msg) => {
    notification.open({
      message: status,
      description: msg,
      placement: "topRight",
      duration: 2,
    });
  }; //End
  /*********************************************************
   *  This function is use to fetch category list
   *********************************************************/
  const [deteteId, setDeteteId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    filter_by: "",
    search: "",
  }); //End
  const [showRequest, setShowRequest] = useState("");
  const getList = async () => {
    setListData([]);
    const options = {
      type: "",
      condition: {
            ...(showRequest?{status : showRequest}:{status: {'$ne' : 'D'}}) 
        },
      select: { token : false, password : false, web_device_id : false, update_date : false, update_by : false, creation_date : false, created_by : false },
      sort: { _id: -1 },
      skip: 0,
      limit: 10,
    };
    const listData = await list(options);
    if (listData.status === true && listData?.result?.length > 0) {
      setListData(listData?.result);
    } else {
      setListData([{
        shop_name : `Data Not Found`
      }]);
    }
  }; //End
  /*********************************************************
   *  This function is use to handle chnage status
   *********************************************************/
  const handleStatus = async (item) => {
    const option = {
      url: `productsmaster/change-product-status`,
      postData: {
        product_id: item?._id,
        status: item.status === "A" ? "I" : "A",
      },
    };
    // const res = await chnageStatus(option);
    // if (res.status === true) {
    //   getList();
    //   handleNotification("Success!", "Status Updated.");
    // } else {
    //   handleNotification("Error!", res?.message);
    // }
  }; //End

  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  useEffect(() => {
    getList();
  }, [showRequest]);

  /*********************************************************
  * This function is load to scrolls the window to the top-left corner of the document
  *********************************************************/
  useEffect(()=>{
    window.scrollTo(0,0)
    }, []);

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={`Store's List`}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      onClick={()=>setShowRequest('')}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Active"
                      className="tabs_main_contnet"
                      onClick={()=>setShowRequest('A')}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label="Inacive"
                      className="tabs_main_contnet"
                      onClick={()=>setShowRequest('I')}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </div>

              </div>
            </Box>
            {/* First Inventory */}
            <ProductList >
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Image</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Created At</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td>
                                      <div className="admin_prodcut_list_span">
                                        <img
                                          src={`${ASSETS_BASE_URL}${items?.image}`}
                                          alt="store-image"
                                        />
                                      </div>
                                    </td>

                                    <td className="admin_user_list_date">
                                    {items?.shop_name}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {items?.email}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {items?.phone}{" "}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {moment(`${items.createdAt}`).format(
                                        "DD/MM/YYYY, h:m A"
                                      )}
                                    </td>
                                    <td>
                                      <Dropdown
                                        overlay={dropdownMenu}
                                        placement="bottomLeft"
                                        arrow
                                        onClick={() => setEditData(items)}
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : (
                              <Skeltonlist row={10} col={7} />
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ProductList>
            {/* Third Inventory */}
          </Box>
        </div>
      </div>
    </>
  );
}
