import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Switch, Dropdown, Upload } from "antd";
import { Container } from "@mui/material";

const ShowVarientsPopUP = ({
  varientToggleProductPopUp,
  opentoggleVarient,
  VarientData,
}) => {
  const handleCancel = () => {
    varientToggleProductPopUp();
  };
  const handleDelete = () => {
    notification.open({
      message: "Notification Title",
      description: " Data Send Successfully .",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };
  // for file uplaod
  const uploadButton = (
    <button className="inventroy_edit_page_antd_uplaod_button" type="button">
      <div className="inventroy_edit_page_antd_choose_file">Choose File</div>
    </button>
  );
  return (
    <Dialog
      open={varientToggleProductPopUp}
      onClose={opentoggleVarient}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              {VarientData?.productData?.name} Varients
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid ">
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>Unit</th>
                      <th>Qty</th>
                      <th>Rate</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {VarientData &&
                      VarientData?.varients?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.unit}</td>
                          <td>{item.qty}</td>
                          <td>{item.rate}</td>
                          <td>
                            <Switch
                              className="categories_switch_"
                              checked={item?.status === "A" ? true : false}
                              //   onClick={() => handleStatus(items)}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form">
                  <div className="inventory_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={opentoggleVarient}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};

export default ShowVarientsPopUP;
