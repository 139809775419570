import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { FaFilter } from "react-icons/fa";
import { Dropdown, Menu, Switch } from "antd";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
// import InventoryDeletePopUp from "./InventoryDeletePoUp";
// import { Link } from "react-router-dom";
import { IoNotificationsCircleSharp } from "react-icons/io5";
// import InventoryEditPagePopUp from "./InventoryEditPagePopUp";
// import InventoryAddProductPopUp from "./InventoryAddProductPopUp";
import ListingTopNavbar from "./ListingTopNavbar";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { FaPlusSquare } from "react-icons/fa";
import ListingDeletePopUp from "./ListingDeletePopUp";

function Listinglist(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

Listinglist.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  // Add product PopUp
  const [inventoryAddProProductPopUp, setAddProductPopUpOpen] = useState(false);
  const inventoryToggleAddProductPopUp = () => {
    setAddProductPopUpOpen(!inventoryAddProProductPopUp);
  };
  //for PopUP modal
  const [inventoryPopUp, setPopUpOpenInventory] = useState(false);
  const inventoryTogglePopUp = () => {
    setPopUpOpenInventory(!inventoryPopUp);
  };

  //remove or delete popup
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    // console.log("Toggle Popup");
    setPopUpOpen(!popUpOpen);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1" onClick={inventoryTogglePopUp}>
        {/* <Link to="/inventoryEditPage"> */}
        <FaEdit />
        <span className="show_span_edit">Edit</span>
        {/* </Link> */}
      </Menu.Item>
      <Menu.Item key="2" onClick={togglePopUp}>
        <MdDelete /> <span className="show_span">Remove</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="container-fluid">
        <ListingTopNavbar></ListingTopNavbar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Recents"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </div>
                <div className="fa_filter_rating_review">
                  <div className="inventory_filter_div">
                    <img
                      src={process.env.PUBLIC_URL + "./images/Filter.svg"}
                      alt=""
                      className="user_iconses"
                    />

                    <a href="#" className="filter_btn_invontry_rating_review">
                      Filters
                    </a>
                  </div>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <Listinglist value={value} index={0}>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 inventory_vendor_add_new_btn">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        Listings
                      </h6>
                      {/* <div>
                        <button
                          type="button"
                          class="btn btn-dark"
                          onClick={inventoryToggleAddProductPopUp}
                        >
                          <FaPlusSquare className="Promotions_faPlus_Squares" />
                          <span className="request_new_categories">
                            Add New
                          </span>
                        </button>
                      </div> */}
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Image</th>
                              <th>Dog Breed</th>
                              <th>Gender</th>
                              <th>Birthday</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "./images/IntentoryTableLogo.png"
                                  }
                                  alt=""
                                />
                              </td>
                              <td>Breed 1 </td>
                              <td>Male</td>
                              <td>22/05/2023</td>
                              <td>
                                <Switch className="categories_switch_" />
                              </td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "./images/IntentoryTableLogo.png"
                                  }
                                  alt=""
                                />
                              </td>
                              <td>Breed 1 </td>
                              <td>Male</td>
                              <td>22/05/2023</td>
                              <td>
                                <Switch className="categories_switch_" />
                              </td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "./images/IntentoryTableLogo.png"
                                  }
                                  alt=""
                                />
                              </td>
                              <td>Breed 1 </td>
                              <td>Male</td>
                              <td>22/05/2023</td>
                              <td>
                                <Switch className="categories_switch_" />
                              </td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "./images/IntentoryTableLogo.png"
                                  }
                                  alt=""
                                />
                              </td>
                              <td>Breed 1 </td>
                              <td>Male</td>
                              <td>22/05/2023</td>
                              <td>
                                <Switch className="categories_switch_" />
                              </td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={50} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Listinglist>
            {/* Second Inventory */}
            <Listinglist value={value} index={1}>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 inventory_vendor_add_new_btn">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        Listings
                      </h6>
                      {/* <div>
                        <button
                          type="button"
                          class="btn btn-dark"
                          onClick={inventoryToggleAddProductPopUp}
                        >
                          <FaPlusSquare className="Promotions_faPlus_Squares" />
                          <span className="request_new_categories">
                            Add New
                          </span>
                        </button>
                      </div> */}
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Image</th>
                              <th>Dog Breed</th>
                              <th>Gender</th>
                              <th>Birthday</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "./images/IntentoryTableLogo.png"
                                  }
                                  alt=""
                                />
                              </td>
                              <td>Breed 1 </td>
                              <td>Male</td>
                              <td>22/05/2023</td>
                              <td>
                                <Switch className="categories_switch_" />
                              </td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "./images/IntentoryTableLogo.png"
                                  }
                                  alt=""
                                />
                              </td>
                              <td>Breed 1 </td>
                              <td>Male</td>
                              <td>22/05/2023</td>
                              <td>
                                <Switch className="categories_switch_" />
                              </td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "./images/IntentoryTableLogo.png"
                                  }
                                  alt=""
                                />
                              </td>
                              <td>Breed 1 </td>
                              <td>Male</td>
                              <td>22/05/2023</td>
                              <td>
                                <Switch className="categories_switch_" />
                              </td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "./images/IntentoryTableLogo.png"
                                  }
                                  alt=""
                                />
                              </td>
                              <td>Breed 1 </td>
                              <td>Male</td>
                              <td>22/05/2023</td>
                              <td>
                                <Switch className="categories_switch_" />
                              </td>
                              <td>
                                <Dropdown
                                  overlay={dropdownMenu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={50} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Listinglist>
            {/* Third Inventory */}
          </Box>
        </div>
        <ListingDeletePopUp popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
      </div>
    </>
  );
}
